import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import Facebook from "../../assets/images/facebook.png";
import Instagram from "../../assets/images/instagram.png";
import Twitter from "../../assets/images/twitter.png";
import Whatsapp from "../../assets/images/whatsapp.png";
import { GET_SITE_CONTENT } from "../../utilities/helper";
import { Container } from 'react-bootstrap'
import './style.scss'

const Footer = () => {
  const [year, setyear] = useState("");
  const content = useSelector((state) => state.content);

  useEffect(() => {
    setyear(new Date().getFullYear());
  }, []);
  const location = useLocation();
  if (
    location.pathname === "" ||
    location.pathname === "/browse_availabilities" ||
    location.pathname === "/post_availabilities" ||
    location.pathname === "/resetpassword" ||
    location.pathname === "/maps"
  )
    return null;
  return (
    <footer id="footer_main">
      <Container fluid='lg' className="footer-main-container">
        <div className="row">
          <div
            className="col-sm-6 col-md-4"
            dangerouslySetInnerHTML={{
              __html: GET_SITE_CONTENT(
                content.site_content_all,
                "footer",
                "footer_company_intro"
              ),
            }}
          ></div>
          <div
            className="col-sm-6 col-md-4"
            dangerouslySetInnerHTML={{
              __html: GET_SITE_CONTENT(
                content.site_content_all,
                "footer",
                "footer_head_office_location"
              ),
            }}
          ></div>
          <div
            className="col-sm-12 col-md-4"
            dangerouslySetInnerHTML={{
              __html: GET_SITE_CONTENT(
                content.site_content_all,
                "footer",
                "footer_operating_hours"
              ),
            }}
          >

          </div>
        </div>
        <div className="row">
          <div className="">
            <div className="divider">
              <hr></hr>
            </div>
            <div className="social-links">
              <img src={Facebook} alt="facebook"></img>
              <img src={Instagram} alt="Instagram"></img>
              <img src={Twitter} alt="Twitter"></img>
              <img src={Whatsapp} alt="Whatsapp"></img>
            </div>
          </div>
        </div>
      </Container>
      <div className="copyright">
        <p className="text-center">
        LocumsConnect 2021-{year} &#169; &nbsp; All Rights Reserved
        </p>
      </div>
    </footer>
  );
};

export default Footer;
