import isEmail from "validator/lib/isEmail";
import { toast } from "react-hot-toast";




// * Validate Login page form
export const ValidateLogin = (values) => {
  const errors = {};
  if (!values.user_name) {
    errors.user_name = "User name is required";
  }
  if (!values.password) {
    errors.password = "Password is required";
  }
  return errors;
};

// * Validate Signup page form
export const ValidateLocumSignup = (values, role) => {
  const errors = {};

  if (!values.role) {
    errors.role = "Role is required";
  }

  if (!values.first_name) {
    errors.first_name = "First name is required";
  }

  if (!values.last_name) {
    errors.last_name = "Last name is required";
  }

  if (!values.user_name) {
    errors.user_name = "Username is required";
  }

  if (!values.email) {
    errors.email = "Email is required";
  }
  if (values.email) {
    if (!isEmail(values.email)) {
      errors.email = "Email is invalid";
    }
  }

  if (!values.password) {
    errors.password = "Password is required";
  }

  if (!values.c_password) {
    errors.c_password = "Confirm Password is required";
  }

  if (values.c_password !== "" && values.password !== "") {
    if (values.c_password !== values.password) {
      errors.c_password = "Passwords don't match";
    }
  }

  if (!values.ahpra_number) {
    errors.ahpra_number = "Ahpra number is required";
  }

  if (values.ahpra_number && values.ahpra_number.trim().length < 13) {
    errors.ahpra_number = "Invalid ahpra number";
  }

  if (!values.mobile_number) {
    errors.mobile_number = "Mobile nummber is required";
  }

  if (values.mobile_number && values.mobile_number.trim().length < 12) {
    errors.mobile_number = "Invalid phone number";
  }

  if (!values.location) {
    errors.location = "This field is required";
  }

  if (!values.agree) {
    errors.agree = "Please agree to these terms and conditions.";
  }

  return errors;
};

// * Validate Practice Signup page form
export const ValidatePracticeSignup = (values, role) => {
  const errors = {};

  if (!values.role) {
    errors.role = "This field is required";
  }

  if (!values.practice_name) {
    errors.practice_name = "Practice name is required";
  }

  if (!values.user_name) {
    errors.user_name = "Username is required";
  }

  if (!values.email) {
    errors.email = "Email is required";
  }

  if (values.email) {
    if (!isEmail(values.email)) {
      errors.email = "Email is invalid";
    }
  }

  if (!values.password) {
    errors.password = "Password is required";
  }

  if (!values.c_password) {
    errors.c_password = "Confirm Password is required";
  }

  if (values.c_password !== "" && values.password !== "") {
    if (values.c_password !== values.password) {
      errors.c_password = "Passwords don't match";
    }
  }

  if (!values.abn_number) {
    errors.abn_number = "Abn number is required";
  }

  if (values.abn_number && values.abn_number.trim().length < 11) {
    errors.abn_number = "Invalid ABN";
  }

  if (!values.mobile_number) {
    errors.mobile_number = "Mobile nummber is required";
  }

  if (values.mobile_number && values.mobile_number.trim().length < 12) {
    errors.mobile_number = "Invalid phone number";
  }

  if (!values.practice_address) {
    errors.practice_address = "Practice address is required";
  }

  if (!values.company) {
    errors.company = "Company is required";
  }

  if (values.company === "other") {
    if (!values.other_company) {
      errors.other_company = "This field is required";
    }
  }

  if (!values.agree) {
    errors.agree = "Please agree to these terms and conditions.";
  }

  return errors;
};

// * Validate Contactus page form
export const ValidateContactus = (values) => {
  const errors = {};
  if (!values.email) {
    errors.email = "Email is required";
  }
  if (!values.user_name) {
    errors.user_name = "Username is required";
  }
  if (!values.subject) {
    errors.subject = "Subject is required";
  }
  if (!values.message) {
    errors.message = "Message is required";
  }
  return errors;
};

// * Validate Contactus page form
export const ValidateResetpassword = (values) => {
  const errors = {};
  if (!values.password) {
    errors.password = "Password is required";
  }
  if (!values.c_password) {
    errors.c_password = "Password is required";
  }
  if (values.password && values.c_password) {
    if (values.password !== values.c_password) {
      errors.c_password = "Passwords don't match";
    }
  }
  return errors;
};

// * Validate Contactus page form
export const ValidateForgotpassword = (values) => {
  const errors = {};
  if (!values.email) {
    errors.email = "Email is required";
  }
  return errors;
};

// * Validate Deleteaccount page form
export const ValidateDeleteaccount = (values) => {
  const errors = {};
  if (!values.password) {
    errors.password = "Password is required";
  }
  return errors;
};

// * Validate change pass form
export const ValidateChangePassword = (values) => {
  const errors = {};
  if (!values.old_password) {
    errors.old_password = "Old password is required";
  }
  if (!values.new_password) {
    errors.new_password = "New password is required";
  }

  if (!values.c_new_password) {
    errors.c_new_password = "Confirm new password is required";
  }
  if (values.old_password !== "" && values.new_password !== "" && values.c_new_password !== "") {
    if (values.new_password !== values.c_new_password) {
      errors.c_new_password = "Passwords don't match";
    }
  }
  // *  if (values.old_password && values.new_password) {
  // *    if (values.old_password !== values.new_password) {
  // *      errors.new_password = "Passwords don't match";
  // *    }
  // *  }
  return errors;
};

// * Validate Updateprofile page form
export const ValidateUpdateProfileLocum = (values, area = null) => {
  const errors = {};
  
  if (!values.user_name) {
    errors.user_name = "User Name is required";
  }
  
  if (!values.email) {
    errors.email = "Email is required";
  } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = "Email address is invalid";
  }

  if (!values.charge_rate) {
    errors.charge_rate = "Weekday charge rate is required";
  }
  if (!values.charge_rate_sat) {
    errors.charge_rate_sat = "Saturday charge rate is required";
  }
  if (!values.charge_rate_sun) {
    errors.charge_rate_sun = "Sunday charge rate is required";
  }

  if (!values.appointment_time) {
    errors.appointment_time = "Appointment Time is required";
  }

  if (!values.language_1 && !values.language_2 && !values.language_3) {
    errors.languages = "At least one language must be selected";
  }

  if (!values.experience_years) {
    errors.experience_years = "Experience years is required";
  } else if (values.experience_years < 0 || values.experience_years > 100) {
    errors.experience_years = "Experience years must be between 0 and 100";
  }

  if (!values.prev_companies || values.prev_companies.length === 0) {
    errors.prev_companies = "Please select at least one previous company";
  }

  if (!values.softwares.length) {
    errors.softwares = "Please select at least one software used";
  }

  if (values.softwares && values.softwares.includes("others") && !values.other_softwares) {
    errors.other_softwares = "Please specify the other software used";
  }

  if (!values.profile) {
    errors.profile = "Profile description is required";
  }

  if (!values.distance_range) {
    errors.distance_range = "Distance range is required";
  }

  if (!values.lat || !values.lng || !values.location) {
    errors.location = "Complete location details are required";
  }

  if (!values.time_range) {
    errors.time_range = "All days' time range is required";
  } else {
    const days = ["mon", "tue", "wed", "thu", "fri", "sat", "sun"];
    let allDaysValid = true;

    days.forEach(day => {
      const time = values.time_range[day];
      if (!time.start || !time.end) {
        allDaysValid = false;
      }
    });

    if (!allDaysValid) {
      errors.time_range = "All days' time is required";
    }
  }

  if(Object.keys(errors).length > 0) {
    toast.error("Please fill all the fields", {
      style: {
        border: "1px solid #e61e38",
        padding: "10px",
      },
    });
  }

  return errors;
};


// * Validate Updateprofile page form
export const ValidateUpdateProfilePractice = (values, area = null) => {
  const errors = {};
  if (!values.user_name) {
    errors.user_name = "User Name is required";
  }
  if (!values.email) {
    errors.email = "Email is required";
  }
  if (!values.mobile_number) {
    errors.mobile_number = "Mobile number is required";
  }
  return errors;
};

// * Validate Updateprofile page form practice
export const ValidateUpdateprofilePractice = (values, area = null) => {
  const errors = {};
  if (!values.user_name) {
    errors.user_name = "User Name is required";
  }
  if (!values.email) {
    errors.email = "Email is required";
  }
  if (!values.sector) {
    errors.sector = "Sector is required";
  }
  if (!values.mobile_number) {
    errors.mobile_number = "Mobile number is required";
  }
  if (!values.mobile_number) {
    errors.mobile_number = "Mobile number is required";
  }
  if (values.mobile_number && values.mobile_number.trim().length < 12) {
    errors.mobile_number = "Invalid phone number";
  }
  if (!values.abn_number) {
    errors.abn_number = "ABN is required";
  }
  if (values.abn_number && values.abn_number.trim().length < 11) {
    errors.abn_number = "Invalid ABN";
  }
  if (!values.company) {
    errors.company = "Company is required";
  }
  if (!values.practice_address) {
    errors.practice_address = "This field is required";
  }
  return errors;
};

// * Validate Updateprofile page for locum
export const ValidateUpdateAccountLocum = (values, area = null) => {
  const errors = {};
  if (!values.user_name) {
    errors.user_name = "User Name is required";
  }
  if (!values.email) {
    errors.email = "Email is required";
  }
  if (!values.sector) {
    errors.sector = "Sector is required";
  }
  if (!values.mobile_number) {
    errors.mobile_number = "Mobile number is required";
  }
  if (values.mobile_number && values.mobile_number.trim().length < 12) {
    errors.mobile_number = "Invalid phone number";
  }
  if (!values.ahpra_number) {
    errors.ahpra_number = "Ahpra number is required";
  }
  if (values.ahpra_number && values.ahpra_number.trim().length < 13) {
    errors.ahpra_number = "Invalid ahpra number";
  }
  return errors;
};

// * Validate Updateprofile page admin
export const ValidateUpdateprofileAdmin = (values, area = null) => {
  const errors = {};
  if (!values.user_name) {
    errors.user_name = "User Name is required";
  }
  if (!values.email) {
    errors.email = "Email is required";
  }
  if (!values.mobile_number) {
    errors.mobile_number = "Mobile number is required";
  }
  if (values.mobile_number && values.mobile_number.trim().length < 12) {
    errors.mobile_number = "Invalid phone number";
  }
  return errors;
};

// * Validate Review modal form
export const ValidateReview = (values) => {
  const errors = {};
  if (!values.feedback) {
    errors.feedback = "Feedback is required";
  }
  if (!values.rating || values.rating === 0) {
    errors.rating = "Rating is required";
  }
  return errors;
};

// * Validate Review modal form
export const ValidateAddAdmin = (values) => {
  const errors = {};
  if (!values.user_name) {
    errors.user_name = "Username is required";
  }
  if (!values.email) {
    errors.email = "Email is required";
  }
  if (values.email) {
    if (!isEmail(values.email)) {
      errors.email = "Email is invalid";
    }
  }
  if (!values.password) {
    errors.password = "Password is required";
  }
  if (!values.c_password) {
    errors.c_password = "Confirm password is required";
  }
  if (values.password !== "" && values.c_password !== "") {
    if (values.password !== values.c_password) {
      errors.c_password = "Passwords don't match";
    }
  }
  return errors;
};

// * Validate Login page form
export const ValidateBookAvailability = (values) => {
  const errors = {};
  if (values.selected_dates.length === 0) {
    errors.selected_dates = "You must select dates to book an availability";
  }
  return errors;
};

// * Validate post availability form
export const ValidatePostAvailability = (values) => {
  const errors = {};
  // *  if (!values.shift_hours) {
  // *    errors.shift_hours = "This field is required";
  // *  }
  // *  if (!values.charge_rate) {
  // *    errors.charge_rate = "Charge rate is required";
  // *  }
  if (values.avaliable_dates.length === 0) {
    errors.avaliable_dates = "Please select dates";
  }
  // *  if (!values.available_time) {
  // *    errors.available_time = "This field is required";
  // *  }
  return errors;
};

// * Validate edit review
export const ValidateEditReview = (values) => {
  const errors = {};
  if (!values.feedback) {
    errors.feedback = "Feedback is required.";
  }
  if (!values.rating) {
    errors.rating = "Rating is required.";
  }
  // *  if (!values.wwa) {
  // *    errors.wwa = "Wwa is required.";
  // *  }
  return errors;
};

// * Validate Add setting
export const ValidateAddSetting = (values) => {
  const errors = {};
  if (!values.key) {
    errors.key = "Key is required.";
  }
  if (!values.value) {
    errors.value = "Value is required.";
  }
  return errors;
};

// * Validate Add site content
export const ValidateSiteContent = (values) => {
  const errors = {};
  if (!values.key) {
    errors.key = "Key is required.";
  }
  if (!values.value) {
    errors.value = "Value is required.";
  }
  if (!values.section) {
    errors.section = "Section is required.";
  }
  return errors;
};

// * Validate Decline/Cancel booking
export const ValidateDeclineBooking = (values) => {
  const errors = {};
  if (!values.cancellation_reason) {
    errors.cancellation_reason = "Cancellation reason is required.";
  }
  return errors;
};

// * Validate subscription checkout
export const ValidateSubscriptionCheckout = (values) => {
  const errors = {};
  if (!values.email) {
    errors.email = "Email is required.";
  }
  if (values.email) {
    if (!isEmail(values.email)) {
      errors.email = "Email is invalid";
    }
  }
  if (!values.first_name) {
    errors.first_name = "First name is required.";
  }
  if (!values.last_name) {
    errors.last_name = "Last name is required.";
  }
  if (!values.mobile_number) {
    errors.mobile_number = "Mobile number is required.";
  }
  return errors;
};
