/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import "./style.scss";
import { Col, Row } from "antd";
import MuiInput from "../../../others/MuiInput";
import { useState } from "react";
import PhoneInput from "../../../others/PhoneInput";
import AntDesignButton from "../../../others/Button/AntDesignButton";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import Label from "../../../others/Label/Label";
import { ValidateSubscriptionCheckout } from "../../../../utilities/validator";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  PurchaseSubFromDefaultPm,
  StripeSubscription,
} from "../../../../store/modules/subscriptionSlice";
import { AddPaymentMethod } from "../../../../store/modules/paymentSlice";
import PaymentMethodCard from "../../../cards/PaymentMethodCard";

const StripeCard = ({ path }) => {
  const dispatch = useDispatch();
  const account = useSelector((state) => state.account);
  const { default_payment_method, subscription_lookup_data } = useSelector(
    (state) => state.subscription
  );
  const elements = useElements();
  const stripe = useStripe();
  const [formError, setFormError] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasDefaultPaymentMethod, setHasDefaultPaymentMethod] = useState(false);

  const [data, setData] = useState({
    email: "",
    first_name: "",
    last_name: "",
    mobile_number: "",
  });

  useEffect(() => {
    if (path === "subscription_checkout" && default_payment_method) {
      setHasDefaultPaymentMethod(true);
    }
  }, []);

  // * Handle input change
  const handleChange = (event) => {
    const { name, value } = event.target;
    setData({ ...data, [name]: value });
    setFormError({ ...formError, [name]: "" });
  };

  //Submit when form validates
  useEffect(() => {
    if (Object.keys(formError).length === 0 && isSubmitting) {
      SubmitForm();
    }
  }, [formError]);

  // * Phone change
  const handlePhoneChange = (e) => {
    if (data.mobile_number !== "") {
      setFormError({ ...formError, mobile_number: "" });
    }
    setData((preval) => {
      return {
        ...preval,
        mobile_number: e,
      };
    });
  };

  // * Handle form submit
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setFormError(ValidateSubscriptionCheckout(data));
    setIsSubmitting(true);
  };

  const SubmitForm = async () => {
    if (!stripe || !elements) {
      return;
    }

    const result = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
      billing_details: {
        email: data.email,
        name: getFullName(data.first_name, data.last_name).trim(),
        phone: data.mobile_number,
      },
      metadata: {
        locums_only_user_id: account.userId,
      },
    });

    if (result && result.error) {
      setFormError({ ...formError, card_number_error: result.error.message });
    } else {
      if (path === "subscription_checkout") {
        dispatch(
          StripeSubscription({
            payment_method: result.paymentMethod,
            user_token: account.user_token,
          })
        );
      } else {
        dispatch(
          AddPaymentMethod({
            payment_method: result.paymentMethod,
            user_token: account.user_token,
          })
        );
      }
    }
  };

  // * Card element options
  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
    hidePostalCode: true,
  };

  const getFullName = (firstName, lastName) => {
    return firstName && firstName !== ""
      ? firstName + " " + lastName
      : lastName;
  };

  const handlePurchaseSubFromDefaultPm = (
    payment_method_id,
    stripe_customer_id
  ) => {
    if (!payment_method_id || !stripe_customer_id) {
      alert("Invalid payment method");
      return;
    }
    dispatch(
      PurchaseSubFromDefaultPm({
        user_token: account.user_token,
        payment_method_id: payment_method_id,
        stripe_customer_id: stripe_customer_id,
      })
    );
  };

  return (
    <Row>
      <Col span={24}>
        {path === "subscription_checkout" ? (
          parseInt(
            subscription_lookup_data &&
              subscription_lookup_data.is_free_trial_availed
          ) === 1 && subscription_lookup_data.free_trial_end_date ? (
            <>
              <h5 style={{ textAlign: "center" }}>
              LocumsConnect monthly basic subscription
              </h5>
              <p>You already have a default payment method.</p>
            </>
          ) : (
            <>
              <h5>
                <u>Get 3 months of LocumsConnect Subscription for Free</u>
              </h5>
              <ul>
                <li>Free subscription for 3 months.</li>
                <li>
                  After 3 months, your subscription will automatically renew on
                  a monthly basis at $49 per month. Cancel at any time before
                  that to avoid being charged.
                </li>
                <li>Valid for new practices/stores only</li>
                <li>No lock in contract. You can cancel at any time, effective at the end of the payment period</li>
              </ul>
            </>
          )
        ) : (
          <>
            <h5 style={{ textAlign: "center" }}>Add payment method</h5>
          </>
        )}
        {hasDefaultPaymentMethod &&
        default_payment_method &&
        Object.keys(default_payment_method).length > 0 &&
        path === "subscription_checkout" ? (
          <>
            <Row
              justify="start"
              style={{ marginTop: "10px", marginBottom: "10px" }}
            >
              <Col xs={24} md={12}>
                <PaymentMethodCard
                  show_actions={false}
                  payment_method_id={default_payment_method.payment_method_id}
                  stripe_customer_id={default_payment_method.stripe_customer_id}
                  issuer={default_payment_method.brand}
                  is_default={default_payment_method.is_default}
                  cvc="xxx"
                  expiry={
                    default_payment_method &&
                    default_payment_method.exp_month &&
                    default_payment_method.exp_month.length > 1
                      ? default_payment_method.exp_month +
                        "/" +
                        default_payment_method.exp_year
                      : "0" +
                        default_payment_method.exp_month +
                        "/" +
                        default_payment_method.exp_year
                  }
                  name={
                    default_payment_method.name
                      ? default_payment_method.name
                      : "N/A"
                  }
                  number={`************${default_payment_method.last_four}`}
                ></PaymentMethodCard>
              </Col>
              <AntDesignButton
                btn_label={"Subscribe for $49"}
                handleClick={() => {
                  handlePurchaseSubFromDefaultPm(
                    default_payment_method.payment_method_id,
                    default_payment_method.stripe_customer_id
                  );
                }}
                block={true}
              ></AntDesignButton>
            </Row>
          </>
        ) : (
          <form onSubmit={handleFormSubmit} className="mt-5">
            <Row gutter={[10, 10]}>
              <Col xs={24} sm={12}>
                <MuiInput
                  label={"First Name"}
                  id="first_name"
                  variant="outlined"
                  inputValue={data.first_name}
                  inputError={formError.first_name}
                  type="text"
                  handleChange={handleChange}
                  name="first_name"
                ></MuiInput>
              </Col>
              <Col xs={24} sm={12}>
                <MuiInput
                  label={"Last Name"}
                  id="last_name"
                  variant="outlined"
                  inputValue={data.last_name}
                  inputError={formError.last_name}
                  type="text"
                  handleChange={handleChange}
                  name="last_name"
                ></MuiInput>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <PhoneInput
                  label="Mobile Number"
                  enableSearch={true}
                  countryCodeEditable={false}
                  searchClass="phone_search_style"
                  inputClass="phone_input_style"
                  preferredCountries={["au"]}
                  inputValue={data.mobile_number}
                  inputError={formError.mobile_number}
                  name="mobile_number"
                  value={data.mobile_number}
                  placeholder="1 (702) 123-4567"
                  handlePhoneChange={handlePhoneChange}
                  is_custom_styled={false}
                />
              </Col>
            </Row>
            <MuiInput
              label={"Email Address"}
              id="email"
              variant="outlined"
              inputValue={data.email}
              inputError={formError.email}
              type="text"
              handleChange={handleChange}
              name="email"
            ></MuiInput>

            <Row>
              <Col span={24}>
                <Label label="Enter Card Details"></Label>
                <CardElement
                  onChange={() => {
                    setFormError({ ...formError, card_number_error: "" });
                  }}
                  options={CARD_ELEMENT_OPTIONS}
                />
                <small className="input_errors">
                  {formError.card_number_error}
                </small>
              </Col>
            </Row>
            <Row className="mt-4">
              <AntDesignButton
                btn_label={
                  path === "subscription_checkout"
                    ? "Subscribe"
                    : "Add payment method"
                }
                handleClick={() => {}}
                block={true}
                htmlType="submit"
              ></AntDesignButton>
            </Row>
          </form>
        )}

        <Row className="mt-4">
          <small>
            By providing your card information, you allow LocumsConnect to charge
            your card for future payments in accordance with their terms.
          </small>
        </Row>

        {path === "subscription_checkout" && (
          <Row className="mt-3">
            <small>
              By Confirming your subscriptions, you allow LocumsConnect to charge
              your card for this payment and future payments in accordance with
              their terms.
            </small>
          </Row>
        )}
      </Col>
    </Row>
  );
};

export default StripeCard;
