import React, { useState, useEffect } from "react";
import Input from "../../components/others/Input";
import MuiInput from "../../components/others/MuiInput";
import { useSelector, useDispatch } from "react-redux";
import {
  ContactUs as SubmitContactUs,
  SetUserSliceBits,
} from "../../store/modules/userSlice";
import { ValidateContactus } from "../../utilities/validator";
import { Button } from "antd";
import "./contactus.css";

const ContactUs = ({ isHomePage = false }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const user = useSelector((state) => state.user);
  const [formError, setFormError] = useState({});
  const [data, setData] = useState({
    user_name: "",
    email: "",
    subject: "",
    message: "",
  });
  useEffect(() => {
    document.title = "LocumsConnect | Contact us";
  }, []);
  const dispatch = useDispatch();
  useEffect(() => {
    if (Object.keys(formError).length === 0 && isSubmitting) {
      submitForm();
    }
  }, [formError]);

  useEffect(() => {
    if (user.isContactUsFormSubmitted) {
      setData({
        user_name: "",
        email: "",
        subject: "",
        message: "",
      });
      dispatch(
        SetUserSliceBits({
          bitToSet: "isContactUsFormSubmitted",
          value: false,
        })
      );
    }
  }, [user.isContactUsFormSubmitted]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setData({ ...data, [name]: value });
    setFormError({ ...formError, [name]: "" });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormError(ValidateContactus(data));
    setIsSubmitting(true);
  };

  const submitForm = () => {
    try {
      dispatch(SubmitContactUs({ data: data }));
    } catch (error) {
      console.log(error);
    }
  };

  const styles = {
    "contact-us": {
      margin: !isHomePage ? "150px 0px" : "0px",
    },
    "contact-us-card": {
      boxShadow: !isHomePage
        ? "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px"
        : "none",
      borderRadius: "10px",
      padding: !isHomePage ? "30px" : "0px",
      backgroundColor: "white",
    },
  };
  return (
    <div style={styles["contact-us"]}>
      <div className="container m-auto">
        <div className="row">
          <div
            className={
              "col-md-12 " + !isHomePage
                ? " col-lg-12 col-xl-12 m-auto"
                : " col-lg-12 col-xl-12"
            }
          >
            <form
              onSubmit={handleSubmit}
              className="contact-us-card"
              style={styles["contact-us-card"]}
            >
              {!isHomePage && (
                <h3 className="text-center mt-3 mb-3">How we can help you?</h3>
              )}
              <MuiInput
                label="Username"
                bold={false}
                id="user_name"
                placeholder="Enter username"
                inputError={formError.user_name}
                inputValue={data.user_name}
                type="text"
                handleChange={handleChange}
                name="user_name"
              ></MuiInput>
              <MuiInput
                label="Email"
                bold={false}
                id="email"
                placeholder="Enter email"
                inputError={formError.email}
                inputValue={data.email}
                type="text"
                handleChange={handleChange}
                name="email"
              ></MuiInput>
              <MuiInput
                label="Subject"
                bold={false}
                id="subject"
                placeholder="eg : Related to booking"
                inputError={formError.subject}
                inputValue={data.subject}
                type="text"
                handleChange={handleChange}
                name="subject"
              ></MuiInput>
              <div className="form-group mt-2">
                <label className="pass_label">Message</label>
                <textarea
                  rows="4"
                  cols="50"
                  className="form-control custom-textarea"
                  name="message"
                  value={data.message}
                  onChange={handleChange}
                ></textarea>
                <small className="input_errors">{formError.message}</small>
              </div>
              <div className="form-group mt-5 mb-4">
                <Button htmlType="submit" block type="primary" shape="round">Submit</Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
