import React, { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Sidebar from "../components/sidebar/Sidebar";
import PrimaryNavbar from "../components/navbar/PrimaryNavbar";
import "./dashboard.scss";

const Dashboard = () => {
  const location = useLocation();
  useEffect(() => {
    document.title = "LocumsConnect | Dashboard";
  }, []);

  useEffect(() => {
    const hash = location.hash;
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);
  return (
    <div className="m-0">
      <div className="row m-0">
        <PrimaryNavbar is_dashboard={true} />
      </div>
      <div className="row mx-0 content_row">
        <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-2 sidebar_col">
          <Sidebar></Sidebar>
        </div>
        <div className="col-sm-12 col-md-9 col-lg-9 col-xl-9 col-xxl-10 content_col">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
