import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { GET_SITE_CONTENT } from "../../utilities/helper";
import { Container, Col, Row } from 'react-bootstrap'
import "./style.scss";

const Help = () => {
  const content = useSelector((state) => state.content);

  useEffect(() => {
    document.title = "LocumsConnect | Help";
  }, []);

  return (
    <section className="help_main">
      <Container fluid='lg'>
        <Row className="help_card">
          <Col sm={12} md={6}
            dangerouslySetInnerHTML={{
              __html: GET_SITE_CONTENT(
                content.site_content_all,
                "help",
                "help_header"
              ),
            }}
          ></Col>
          <Col sm={12} md={6}>
            <div
              dangerouslySetInnerHTML={{
                __html: GET_SITE_CONTENT(
                  content.site_content_all,
                  "help",
                  "help_guide_para"
                ),
              }}
            ></div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Help;
