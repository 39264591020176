/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import Button from "@mui/material/Button";
import { Result } from "antd";
import {
  LocumBookingResponse,
  SetAvailabilitySliceBits,
} from "../../../store/modules/bookingSlice";
import { ValidateDeclineBooking } from "../../../utilities/validator";

const DeclineBooking = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const account = useSelector((state) => state.account);
  const availabilites = useSelector((state) => state.bookings);
  const [formError, setFormError] = useState({});
  const [data, setData] = useState({
    cancellation_reason: "",
    booking_id: 0,
    status: false,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDeclineSuccess, setIsdeclineSuccess] = useState(false);
  const location = useLocation();

  //* Handle input change

  const handleChange = (event) => {
    const { name, value } = event.target;
    setData({ ...data, [name]: value });
    setFormError({ ...formError, [name]: "" });
  };

  //* Set booking id
  useEffect(() => {
    if (location.state.booking_id) {
      setData({
        ...data,
        booking_id: location.state.booking_id,
      });
    }
  }, []);

  //* CHeck if submitted
  useEffect(() => {
    if (availabilites.isRequestStatusUpdated) {
      setIsdeclineSuccess(true);
      dispatch(SetAvailabilitySliceBits({ bitToSet: "isRequestStatusUpdated", value: false }));
    }
  }, [availabilites.isRequestStatusUpdated]);

  //* Reject booking
  const handleDeclineBooking = (booking_id) => {
    if (data.booking_id !== location.state.booking_id) {
      alert("Booking id error");
      return;
    }

    setFormError(ValidateDeclineBooking(data));
    setIsSubmitting(true);
  };

  // * Submit when form validates
  useEffect(() => {
    if (Object.keys(formError).length === 0 && isSubmitting) {
      submitForm();
    }
  }, [formError]);

  //* Handle go back to booking requests
  const handleGoBack = () => {
    navigate(-1);
  };

  const submitForm = () => {
    confirmAlert({
      message: "Are you sure you want to decline booking?",
      buttons: [
        {
          label: "Yes",
          onClick: () =>
            dispatch(
              LocumBookingResponse({
                data: data,
                user_token: account.user_token,
              })
            ),
        },
        {
          label: "No",
        },
      ],
    });
  };

  return (
    <div className="decline_booking_request">
      {isDeclineSuccess ? (
        <>
          <Result
            status="success"
            title="Your booking request has been cancelled. The practice will receive notification of the cancellation."
            subTitle="Have a good day!"
          />
        </>
      ) : (
        <>
          <div className="row m-0">
            <div className="col">
              <div className="form-group mt-2">
                <label className="pass_label">
                  <strong>Please state reason for decline</strong>
                </label>
                <textarea
                  className="form-control custom-textarea"
                  id="cancellation_reason"
                  rows="3"
                  name="cancellation_reason"
                  onChange={handleChange}
                  value={data.cancellation_reason}
                ></textarea>
                <small className="input_errors">
                  {formError.cancellation_reason}
                </small>
              </div>
            </div>
          </div>
          <div className="row m-0 mt-5 mb-4">
            <div className="col">
              <div className="actions">
                <Button
                  onClick={() =>
                    handleDeclineBooking(location.state.booking_id)
                  }
                  color="success"
                  type="button"
                  sx={{
                    backgroundColor: "#4493c5",
                    ":hover": { backgroundColor: "#4493c5" },
                  }}
                  variant="contained"
                >
                  Decline booking
                </Button>
                <Button
                  onClick={handleGoBack}
                  color="success"
                  type="button"
                  sx={{
                    ml: "10px",
                    backgroundColor: "#4493c5",
                    ":hover": { backgroundColor: "#4493c5" },
                  }}
                  variant="contained"
                >
                  Back
                </Button>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default DeclineBooking;
