import { createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import axios from "axios";
import { SHOW_ERROR_TOAST, SHOW_SUCCESS_TOAST } from "../../utilities/helper";
import { SetBackDrop } from "./backDropSlice";

const initialState = {
  api_version: 0,
  site_content: [],
  site_content_count: 0,
  site_content_all: [],
  is_content_fetched: false,
};

const contentSlice = createSlice({
  name: "content",
  initialState,
  reducers: {
    setSiteContent(state, { payload }) {
      if (payload.code == 200) {
        return {
          ...state,
          site_content: payload.data.site_content,
          site_content_count: payload.data.site_content_count,
        };
      }
    },
    addSiteContent(state, { payload }) {
      if (payload.code == 200) {
        if (payload.data.update) {
          state.site_content = state.site_content.map((item) => {
            if (item.id === payload.data.data.id) {
              item.value = payload.data.data.value;
            }
            return item;
          });
        } else {
          state.site_content.push(payload.data.data);
        }
      }
    },
    setSiteContentAll(state, { payload }) {
      if (payload.code == 200) {
        return {
          ...state,
          site_content_all: payload.data.site_content,
          is_content_fetched: true,
        };
      }
    },
    setContentSliceBits(state, { payload }) {
      state[payload.bitToSet] = payload.value;
    },
  },
});

const {
  setSiteContent,
  addSiteContent,
  setContentSliceBits,
  setSiteContentAll,
} = contentSlice.actions;
export default contentSlice.reducer;

//----------------------------------------------------------------

export function SetContentSliceBits(payload) {
  return async (dispatch) => {
    dispatch(setContentSliceBits(payload));
  };
}

export function GetSiteContent(payload) {
  return async (dispatch) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URI}/api/get_site_content`,
        {
          section: payload.data.section,
          name: payload.data.name,
          rows_per_page: payload.data.rows_per_page,
          start: payload.data.start,
        },
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${payload.user_token}`,
          },
        }
      )
      .then((response) => {
        dispatch(setSiteContent(response.data));
      })
      .catch((error) => {
        console.log(error);
        SHOW_ERROR_TOAST(error);
      });
  };
}

export function AddSiteContent(payload) {
  return async (dispatch) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URI}/api/add_site_content`,
        {
          key: payload.data.key,
          value: payload.data.value,
          section: payload.data.section,
        },
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${payload.user_token}`,
          },
        }
      )
      .then((response) => {
        dispatch(addSiteContent(response.data));
        SHOW_SUCCESS_TOAST(response);
      })
      .catch((error) => {
        console.log(error);
        SHOW_ERROR_TOAST(error);
      });
  };
}

export function GetContent(payload) {
  return async (dispatch) => {
    dispatch(SetBackDrop(true));
    axios
      .get(`${process.env.REACT_APP_API_URI}/api/get_content`, {
        headers: {
          Accept: "application/json",
        },
      })
      .then((response) => {
        dispatch(SetBackDrop(false));
        dispatch(setSiteContentAll(response.data));
      })
      .catch((error) => {
        dispatch(SetBackDrop(false));
        console.log(error);
        SHOW_ERROR_TOAST(error);
      });
  };
}
