import React, { useState, useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Tooltip from "@mui/material/Tooltip";
import DatePicker from "react-datepicker";
import { PostAvaliability as SubmitTask, SetAvailabilitySliceBits } from "store/modules/bookingSlice";
import { useSelector, useDispatch } from "react-redux";
import { Link, NavLink, useLocation } from "react-router-dom";
import { GetLocumPostedDates } from "store/modules/userSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Divider, Row } from "antd";
import AntDesignButton from "components/others/Button/AntDesignButton";
import { Segmented } from 'antd';
import { ROLES } from "utilities/enumerations";
import Step2 from "./Step2";
import { Container } from 'react-bootstrap'
import dayjs from "dayjs";
import "./style.scss";

import CustomDesktopHeader from "./CustomDesktopHeader";

const CustomHeader = ({
  monthDate,
  customHeaderCount,
  decreaseMonth,
  increaseMonth,
}) => (
  <div>
    {(customHeaderCount === 0 || customHeaderCount === 3) && (
      <>
        <button
          aria-label="Previous Month"
          className={
            "react-datepicker__navigation react-datepicker__navigation--previous"
          }
          onClick={decreaseMonth}
        >
          <span
            className={
              "react-datepicker__navigation-icon react-datepicker__navigation-icon--previous"
            }
          >
            {"<"}
          </span>
        </button>
        <span className="react-datepicker__current-month">
          {monthDate.toLocaleString("en-US", {
            month: "long",
            year: "numeric",
          })}
        </span>
        <button
          aria-label="Next Month"
          className={
            "react-datepicker__navigation react-datepicker__navigation--next"
          }
          onClick={increaseMonth}
        >
          <span
            className={
              "react-datepicker__navigation-icon react-datepicker__navigation-icon--next"
            }
          >
            {">"}
          </span>
        </button>
      </>
    )}
    {customHeaderCount !== 0 && customHeaderCount !== 3 && (
      <span className="react-datepicker__current-month">
        {monthDate.toLocaleString("en-US", {
          month: "long",
          year: "numeric",
        })}
      </span>
    )}
  </div>
);


const PostAvaliabilities = () => {
  const dispatch = useDispatch();
  const account = useSelector((state) => state.account);
  const { is_availability_posted, is_profile_incomplete_error } = useSelector((state) => state.bookings);
  const { locum_posted_dates } = useSelector((state) => state.user);
  const location = useLocation();
  const [data, setData] = useState({
    shift_hours: "",
    avaliable_dates: [],
    charge_rate: "",
    available_time: "flexible",
    is_update: false,
  });
  const [selectionMode, setSelectionMode] = useState('Single');
  const [formError, setFormError] = useState({});
  // const [isSubmitting, setIsSubmitting] = useState(false);
  // const [redirect, setRedirect] = useState(false);
  const [datesToSave, setDatesToSave] = useState([]);
  const [datesToShow, setDatesToShow] = useState([]);
  const [calenderNumber] = useState(4);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [postedDates, setPostedDates] = useState([]);
  const [showProfileIncomplete, setShowProfileIncomplete] = useState(false);
  const [steps, setSteps] = useState(0);
  const [showInfoAlert, setShowInfoAlert] = useState(false);
  const [infoAlertMsg, setInfoAlertMsg] = useState("");

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  // * Set page title
  useEffect(() => {
    document.title = "LocumsConnect | Post Availabilties";
  }, []);

  // * Get already posted dates
  useEffect(() => {
    if (account.role === ROLES.locum) {
      dispatch(
        GetLocumPostedDates({
          user_token: account.user_token,
        })
      );
    }
  }, [account.role, account.user_token, dispatch]);

  // * Set already poseted dates in arrays and handle update case
  useEffect(() => {
    if (locum_posted_dates && locum_posted_dates.length > 0) {
      var postedDatesArr = locum_posted_dates.map((item) => (new Date(item)));
      if (location.state && location.state.is_editable) {
        setData({
          ...data,
          is_update: true,
          avaliable_dates: (locum_posted_dates),
        });
        setDatesToSave(locum_posted_dates);
        setDatesToShow(postedDatesArr);
      } else {
        setPostedDates(postedDatesArr);
        setDatesToSave(locum_posted_dates);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state, locum_posted_dates]);

  // * Handle range select
  const handleRangeChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };


  // * On date range change get all dates between range
  useEffect(() => {
    if (startDate && endDate) {
      const preArraySave = [...datesToSave];
      const preArrayShow = [...datesToShow];

      const name = "avaliable_dates";
      const newArrSave = getDates(startDate, endDate, preArraySave, true);
      const newArrShow = getDates(startDate, endDate, preArrayShow);

      setDatesToSave(newArrSave);
      setDatesToShow(newArrShow);
      setData({ ...data, [name]: newArrSave });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  // * Handle selection mode
  const handleSelectionMode = (val) => {
    setStartDate(null);
    setEndDate(null);
    setSelectionMode(val);
  };

  // * Check if posted successfully
  useEffect(() => {
    if (is_availability_posted) {
      dispatch(SetAvailabilitySliceBits({ bitToSet: "is_availability_posted", value: false }));
      setSteps(2);
    }
  }, [dispatch, is_availability_posted]);

  // * Check if profile incomplete error
  useEffect(() => {
    if (is_profile_incomplete_error) {
      dispatch(SetAvailabilitySliceBits({ bitToSet: "is_profile_incomplete_error", value: false }));
      setShowProfileIncomplete(true);
    }
  }, [dispatch, is_profile_incomplete_error]);

  // * Handle clear claneder btn
  const clearDates = () => {
    setData({ ...data, avaliable_dates: [] });
    setDatesToSave([]);
    setDatesToShow([]);
    setStartDate(null);
    setEndDate(null);
  };

  // * Hnadle form submit
  const handleSubmitClick = (e) => {
    e.preventDefault();
    if (location?.state?.is_editable) {
      setSteps(1);
    } else {
      if (data.avaliable_dates.length === 0) {
        setFormError({ ...formError, avaliable_dates: "Please select dates" });
        return
      }
      setSteps(1);
    }
  };

  // * Handle date change
  const handleDateChange = (date) => {
    var dateSave = dayjs(date).format("YYYY-MM-DD 00:00:00");
    var dateShow = date;
    const arraySave = [...datesToSave];
    const arrayShow = [...datesToShow];
    const indexSave = findIndexDate(arraySave, dateSave);
    if (indexSave >= 0) {
      arraySave.splice(indexSave, 1);
    } else {
      arraySave.push(dateSave);
    }
    const indexShow = findIndexDate(arrayShow, dateShow);
    if (indexShow >= 0) {
      arrayShow.splice(indexShow, 1);
    } else {
      arrayShow.push(dateShow);
    }
    setDatesToSave(arraySave);
    setDatesToShow(arrayShow);
    setData((prevData) => ({
      ...prevData, avaliable_dates: arraySave
    }));
    setFormError({ ...formError, avaliable_dates: "" });
  };

  // * Handle final submit after no errors
  const submitForm = () => {
    if (!account.role || !account.user_token) {
      setShowInfoAlert(true);
      setInfoAlertMsg("You must be logged in to post availabilities.");
      return;
    } else {
      if (account.role !== ROLES.locum) {
        setShowInfoAlert(true);
        setInfoAlertMsg(
          'Your account type must be "Locum" to post availabilities.'
        );
        return;
      }
    }

    dispatch(
      SubmitTask({
        data: data,
        token: account.user_token,
      })
    );
  };

  // * Find dates in arrays
  const findIndexDate = (dates, date) => {
    const dateTime = new Date(date).getTime();
    return dates.findIndex(
      (item) => item && new Date(item).getTime() === dateTime
    );
  };

  // * Get dates array between two dates. Caution! carefully handle this functions as it handles all range selection and edit range
  const getDates = (startDate, stopDate, datesArr, forSave = false) => {
    startDate = new Date(startDate);
    stopDate = new Date(stopDate);
    var newDatesArr = [...datesArr];
    var currentDate = startDate;
    while (currentDate <= stopDate) {
      const localnewDateForArr = new Date(currentDate);

      const index = findIndexDate(newDatesArr, localnewDateForArr);
      if (index >= 0) {
        newDatesArr.splice(index, 1);
      } else {
        if (forSave) {
          var date_str = dayjs(localnewDateForArr).format("YYYY-MM-DD 00:00:00");
          // To check if date in range is already a poseted date ignore this while editing
          if (!data.is_update && !locum_posted_dates.includes(date_str)) {
            newDatesArr.push(date_str);
          }
          if (location.state && location.state.is_editable && data.is_update) {
            newDatesArr.push(date_str);
          }
        } else {
          newDatesArr.push(localnewDateForArr);
        }
      }

      var dateAdded = new Date(currentDate.setDate(currentDate.getDate() + 1));
      currentDate = dateAdded;
    }
    return newDatesArr.length > 0
      ? newDatesArr.sort((date1, date2) => date1 - date2)
      : newDatesArr;
  };

  return (
    // <Row className="postavailabilities">
    //   <Col span={24}>
    <Container className="postavailabilities">
      <Row justify="center">
        <Col
          span={24}
          className="availability-card"
        >
          {steps === 0 &&
            <>
              <div className="availability-card-header">
                <h5>{location.state && location.state.is_editable ? "Edit Availability" : "Post Availability"}</h5>
              </div>
              <div style={{
                color: 'black',
                padding: '10px 40px 0px 40px',
                margin: '0px',
              }}>
                {location.state && location.state.is_editable ? (
                  ''
                ) : (
                  <>
                    Please complete your <Link to={`/${account.role}/profile#profile`}>Profile</Link> before posting your availability
                  </>
                )}
              </div>
              <Divider></Divider>
              <Row>
                <Col span={24} className="calender_mode_selector">
                  <p>Selection Mode</p>
                  <Segmented
                    value={selectionMode}
                    size="large"
                    options={['Single', 'Range']}
                    onChange={(value) => { handleSelectionMode(value) }}
                  />
                </Col>
              </Row>
              <br></br>
              <div className="availability-card-body">
                <div className="react-datepicker-div">
                  <DatePicker
                    excludeDates={postedDates}
                    selectsRange={selectionMode === 'Range' ? true : undefined}
                    onChange={(date) =>
                      selectionMode === 'Range' ? handleRangeChange(date) : handleDateChange(date)
                    }
                    inline
                    monthsShown={calenderNumber}
                    highlightDates={datesToShow}
                    minDate={new Date()}
                    startDate={startDate ? new Date(startDate) : null}
                    endDate={endDate ? new Date(endDate) : null}
                    disabledKeyboardNavigation
                    renderCustomHeader={isMobile ? CustomHeader : CustomDesktopHeader}
                  />

                  <p className="w-100 d-block text-center m-0">
                    <small className="input_errors mx-3">
                      {formError.avaliable_dates}
                    </small>
                  </p>
                </div>
                <div className="mt-1">
                  <div className="m-0 p-0 mx-2">
                    <Tooltip title="Clear Dates" placement="top">
                      <IconButton onClick={clearDates} color="warning">
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                </div>
                <div className="form-group mt-5">
                  <AntDesignButton
                    handleClick={handleSubmitClick}
                    block={true}
                    btn_label={location.state && location.state.is_editable ? "Update" : "Submit"}
                  />
                </div>
              </div>
            </>
          }
          {steps === 1 &&
            <Row>
              <Col span={24}>
                <div className="availability-card-header mx-5">
                  <h5>Please review your posting dates</h5>
                </div>
                <div className="row m-0 mx-5">
                  {showInfoAlert && (
                    <div
                      className="alert alert-primary mb-2"
                      role="alert"
                    >
                      <FontAwesomeIcon
                        className=""
                        icon="fa-solid fa-circle-info"
                      />
                      &nbsp; {infoAlertMsg}
                    </div>
                  )}
                  {showProfileIncomplete && (
                    <div className="alert alert-primary" role="alert">
                      <FontAwesomeIcon
                        className=""
                        icon="fa-solid fa-circle-info"
                      />
                      &nbsp; Your account & profile is incomplete.&nbsp;
                      <NavLink
                        to={`/${account.role}`}
                        state={{ is_profile_incomplete: true }}
                      >
                        Click here
                      </NavLink>
                      &nbsp; to complete your account and profile.
                    </div>
                  )}
                </div>
                <hr></hr>
                <div className="review_dates mx-5">
                  {data.avaliable_dates.length > 0 ? (
                    data.avaliable_dates.map((item, index) => {
                      return (
                        <p key={index}>
                          {dayjs(item).format("DD/MM/YYYY") +
                            " " +
                            dayjs(item).format("dddd") +
                            " "}
                        </p>
                      );
                    })
                  ) : <p>No dates selected</p>}
                </div>
                <div className="review_step_actions mx-5">
                  <AntDesignButton
                    btn_label="Back"
                    margin_y={[10, 0]}
                    margin_x={[10, 0]}
                    handleClick={() => {
                      setSteps(0);
                      setInfoAlertMsg("");
                      setShowInfoAlert(false);
                    }}
                  />

                  <AntDesignButton
                    margin_y={[10, 0]}
                    margin_x={[10, 0]}
                    handleClick={submitForm}
                    btn_label="Confirm Availabiltie Dates"
                  />
                </div>
              </Col>
            </Row>
          }
          {steps === 2 &&
            <Step2
              role={account.role}
              is_update={data.is_update}
            ></Step2>
          }
        </Col>
      </Row>
    </Container >
  );
}
export default PostAvaliabilities;