/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import MuiInput from "../../components/others/MuiInput";
import AddressInput from "../../components/others/AddressInput/AddressInput";
import MuiPasswordInput from "../../components/others/MuiPasswordInput";
import PhoneInput from "../../components/others/PhoneInput";
import {
  Signup as signupuser,
  SetAccountSliceBits,
} from "../../store/modules/accountSlice";
import { ValidateLocumSignup } from "../../utilities/validator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReCAPTCHA from "react-google-recaptcha";
import "./style.scss";
import { Checkbox, Col, Divider, Row } from "antd";
import AntDesignButton from "../../components/others/Button/AntDesignButton";

const LocumSignup = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const account = useSelector((state) => state.account);
  const [formError, setFormError] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const captchaRef = useRef(null);
  const [data, setData] = useState({
    agree: false,
    role: "locum",
    first_name: "",
    last_name: "",
    user_name: "",
    email: "",
    password: "",
    c_password: "",
    mobile_number: "",
    ahpra_number: "",
    provider_number: "",
    address_object: {},
    re_captcha_token: "",
    location: "",
    lat: "",
    lng: "",
    time_range: {
      mon: {
        start: "09:00",
        end: "17:00",
      },
      tue: {
        start: "09:00",
        end: "17:00",
      },
      wed: {
        start: "09:00",
        end: "17:00",
      },
      thu: {
        start: "09:00",
        end: "17:00",
      },
      fri: {
        start: "09:00",
        end: "17:00",
      },
      sat: {
        start: "09:00",
        end: "17:00",
      },
      sun: {
        start: "09:00",
        end: "17:00",
      },
    },
  });

  // * Page title
  useEffect(() => {
    document.title = "LocumsConnect | Signup";
  }, []);

  // * This will check on submit button if formError object is empty or not
  useEffect(() => {
    if (Object.keys(formError).length === 0 && isSubmitting) {
      submitForm();
    }
  }, [formError]);

  // * Redirect on signup
  useEffect(() => {
    if (account.isSignedUp && redirect) {
      dispatch(
        SetAccountSliceBits({
          bitToSet: "isSignedUp",
          value: false,
        })
      );
      setTimeout(function () {
        navigate("/verify_email");
      }, 1000);
    }
  }, [account.isSignedUp]);

  // * Form Submit
  const sendData = (e) => {
    e.preventDefault();
    setFormError(ValidateLocumSignup(data));
    setIsSubmitting(true);
  };

  // * Handle input Changes
  const handleChange = (event) => {
    const { name, value } = event.target;
    setData({ ...data, [name]: value });
    setFormError({ ...formError, [name]: "" });
  };

  // * Location change
  const handlePlaceChange = (val) => {
    // eslint-disable-next-line no-new-object
    var address_obj = new Object();
    val.address_components.forEach((element) => {
      address_obj[element.types[0]] = element.long_name;
    });
    if (val) {
      setData((prevState) => ({
        ...prevState,
        lng: val.geometry.location.lng(),
        lat: val.geometry.location.lat(),
        location: val.formatted_address,
        address_object: address_obj,
      }));
    }
  };

  // * Clear location error if populated
  useEffect(() => {
    if (data.location !== "") {
      setFormError({ ...formError, location: "" });
    }
  }, [data.location]);

  // * Clear agree error if populated
  useEffect(() => {
    if (data.agree) {
      setFormError({ ...formError, agree: "" });
    }
  }, [data.agree]);

  // * Phone change
  const handlePhoneChange = (e) => {
    if (data.mobile_number !== "") {
      setFormError({ ...formError, mobile_number: "" });
    }
    setData((preval) => {
      return {
        ...preval,
        mobile_number: e,
      };
    });
  };

  // * Send Signup Request
  const submitForm = () => {
    try {
      dispatch(signupuser(data));
      setRedirect(true);
    } catch (error) {
      console.log(error);
    }
  };

  // * Handle reCaptcha change
  const handleRecaptchaChange = () => {
    const token = captchaRef.current.getValue();
    setData((preVal) => {
      return {
        ...preVal,
        re_captcha_token: token,
      };
    });
  };

  const goBack = () => {
    navigate(-1);
  };

  return (
    <Row id="signup">
      <Col span={24}>
        <Row justify="center">
          <Col className="signup-card" xs={24} sm={20} md={18} xl={12} xxl={10}>
            <form onSubmit={sendData}>
              <Row className="signup-card-header">
                <Col span={24}>
                  <NavLink onClick={goBack} className="goto-signup">
                    <FontAwesomeIcon
                      icon="fa-solid fa-arrow-left"
                      style={{ color: "#4493c5" }}
                    />
                  </NavLink>
                  <h3 className="t text-center">Sign Up</h3>
                </Col>
              </Row>
              <Row className="signup-card-body">
                <Col span={24}>
                  {/* Full name */}
                  <Row gutter={[20]} style={{ marginBottom: "20px" }}>
                    <Col xs={24} sm={12}>
                      <MuiInput
                        label="First Name"
                        id="first_name"
                        // placeholder="Enter full name"
                        inputError={formError.first_name}
                        inputValue={data.first_name}
                        type="text"
                        handleChange={handleChange}
                        name="first_name"
                      ></MuiInput>
                    </Col>
                    <Col xs={24} sm={12}>
                      <MuiInput
                        label="Last Name"
                        id="last_name"
                        // placeholder="Enter full name"
                        inputError={formError.last_name}
                        inputValue={data.last_name}
                        type="text"
                        handleChange={handleChange}
                        name="last_name"
                      ></MuiInput>
                    </Col>
                  </Row>

                  {/* Email, Username */}
                  <Row gutter={[20]} style={{ marginBottom: "20px" }}>
                    <Col xs={24} sm={12}>
                      <MuiInput
                        label="Email"
                        id="userEmail"
                        placeholder="Enter your Email"
                        inputError={formError.email}
                        inputValue={data.email}
                        type="text"
                        handleChange={handleChange}
                        name="email"
                      ></MuiInput>
                    </Col>
                    <Col xs={24} sm={12}>
                      <MuiInput
                        label="Username"
                        id="userName"
                        placeholder="Enter Username"
                        inputError={formError.user_name}
                        inputValue={data.user_name}
                        type="text"
                        handleChange={handleChange}
                        name="user_name"
                      ></MuiInput>
                    </Col>
                  </Row>

                  {/* Password, C_Password */}
                  <Row gutter={[20]} style={{ marginBottom: "20px" }}>
                    <Col xs={24} sm={12}>
                      <MuiPasswordInput
                        label="Password"
                        id="userPassword"
                        inputError={formError.password}
                        inputValue={data.password}
                        handleChange={handleChange}
                        name="password"
                      ></MuiPasswordInput>
                    </Col>
                    <Col xs={24} sm={12}>
                      <MuiPasswordInput
                        label="Confirm Password"
                        id="c_Password"
                        placeholder="Enter your password again"
                        inputError={formError.c_password}
                        inputValue={data.c_password}
                        handleChange={handleChange}
                        name="c_password"
                      ></MuiPasswordInput>
                    </Col>
                  </Row>

                  {/* Ahpra No */}
                  <Row gutter={[20]} style={{ marginBottom: "20px" }}>
                    <Col span={24}>
                      <MuiInput
                        label="Ahpra Number"
                        id="ahpranumber"
                        placeholder="Enter your ahpra number"
                        inputError={formError.ahpra_number}
                        inputValue={data.ahpra_number}
                        type="text"
                        handleChange={handleChange}
                        name="ahpra_number"
                      ></MuiInput>
                    </Col>
                  </Row>

                  {/* Mobile No */}
                  <Row gutter={[20]} style={{ marginBottom: "20px" }}>
                    <Col span={24}>
                      <PhoneInput
                        label="Mobile Number"
                        enableSearch={true}
                        countryCodeEditable={false}
                        searchClass="phone_search_style"
                        inputClass="phone_input_style"
                        preferredCountries={["au"]}
                        inputValue={data.mobile_number}
                        inputError={formError.mobile_number}
                        name="mobile_number"
                        value={data.mobile_number}
                        placeholder="1 (702) 123-4567"
                        handlePhoneChange={handlePhoneChange}
                      />
                    </Col>
                  </Row>

                  <Row gutter={[20]} style={{ marginBottom: "20px" }}>
                    <Col span={24}>
                      <AddressInput
                        label="Address"
                        id="address"
                        inputError={formError.location}
                        type="text"
                        handlePlaceChange={handlePlaceChange}
                        name="location"
                        inputValue={data.location}
                        handleChange={handleChange}
                      ></AddressInput>
                    </Col>
                  </Row>

                  {/* Re captcha */}
                  <Row gutter={[20]} style={{ marginBottom: "20px" }}>
                    <Col span={24}>
                      <ReCAPTCHA
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                        sitekey={
                          process.env.REACT_APP_GOOGLE_RE_CAPTCHA_SITE_KEY
                        }
                        ref={captchaRef}
                        onChange={handleRecaptchaChange}
                      ></ReCAPTCHA>
                    </Col>
                  </Row>

                  {/* submit button */}
                  <Row>
                    <AntDesignButton
                      margin_y={[30, 10]}
                      block={true}
                      htmlType="submit"
                      btn_label="Continue"
                      handleClick={() => { }}
                    />
                  </Row>
                  <br></br>
                  <br></br>
                  <Row>
                    <Checkbox
                      isDisabled={true}
                      // isSelected={emailChecked}
                      size="md"
                      name="email"
                      onChange={(event) => {
                        setData((prevState) => ({
                          ...prevState,
                          agree: event.target.checked,
                        }));
                      }}
                    >
                      <span>
                        By proceeding, I agree to Locum's &nbsp;
                        <NavLink
                          className="login-links"
                          to="/termsconditions#termsconditions"
                        >
                          Terms & Conditions &nbsp;
                        </NavLink>
                        <NavLink
                          className="login-links"
                          to="/termsconditions#termsconditions"
                        >
                          Community Guidelines
                        </NavLink>
                        , & &nbsp;
                        <NavLink
                          className="login-links"
                          to="/termsconditions#termsconditions"
                        >
                          Privacy Policy
                        </NavLink>
                      </span>
                    </Checkbox>
                  </Row>
                  <small className="input_errors">{formError.agree}</small>
                  <Divider />

                  <Row justify="space-between">
                    <p>Already have an account?</p>
                    <NavLink className="login-links text-right" to="/login">
                      Log in
                    </NavLink>
                  </Row>
                </Col>
              </Row>
            </form>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default LocumSignup;
