import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { GetAllLocumPostedAndBookedDates } from "../../../store/modules/bookingSlice";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import "./style.scss";
import { useNavigate } from "react-router-dom";
import { SetUserSliceBits } from "../../../store/modules/userSlice";
import { ColorPicker, Row } from "antd";
import IsProcessing from "../../../components/others/IsProcessing/IsProcessing";

const LocumCalender = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [calenderNumber] = useState(4);
  const account = useSelector((state) => state.account);
  const { locum_booked_dates, locum_posted_dates, a_is_processing } = useSelector(
    (state) => state.bookings
  );
  const [bookedDates, setBookedDates] = useState([]);
  const [postedDates, setPostedDates] = useState([]);

  const highlightWithRanges = [
    {
      "react-datepicker__day--highlighted-custom-1":
        postedDates.length > 0 ? postedDates : [],
    },
    {
      "react-datepicker__day--highlighted-custom-2":
        bookedDates.length > 0 ? bookedDates : [],
    },
  ];

  // * Get locum posted and booked dates
  useEffect(() => {
    dispatch(
      GetAllLocumPostedAndBookedDates({
        token: account.user_token,
        show_loader: true,
      })
    );
  }, [account.user_token, dispatch]);

  // * Populate booked dates
  useEffect(() => {
    if (locum_booked_dates && locum_booked_dates.length > 0) {
      var bookedDatesArr = locum_booked_dates.map((item) => (new Date(item)));;
      setBookedDates(bookedDatesArr);
    }
  }, [locum_booked_dates]);

  // * Populate posted dates
  useEffect(() => {
    if (locum_posted_dates && locum_posted_dates.length > 0) {
      var postedDatesArr = locum_posted_dates.map((item) => (new Date(item)));
      setPostedDates(postedDatesArr);
    }
  }, [locum_posted_dates]);

  // * Handle edit calender btn
  const handleEditCalender = () => {
    dispatch(
      SetUserSliceBits({
        bitToSet: "locum_posted_dates",
        value: [],
      })
    );
    navigate(
      `/post_availabilities`,
      {
        state: {
          is_editable: true,
        },
      },
      true
    );
  };

  return (
    <div className="my-calender-main" id="my-calender-main">
      <h5>My Calender</h5>
      <hr></hr>
      {!a_is_processing ? (
        <div className="my-calender">
          <Button
            type="submit"
            sx={{
              width: 200,
              height: 45,
              backgroundColor: "#4493c5",
              marginBottom: "20px",
              ":hover": { backgroundColor: "#4493c5" },
            }}
            onClick={handleEditCalender}
            variant="contained"
            color="primary"
          // startIcon={<SaveAltIcon />}
          >
            Edit
          </Button>
          <Row className="colors_pickers">
            <ColorPicker value="#4493c5" disabled />
            <span>Posted dates.</span>
          </Row>
          <Row className="colors_pickers">
            <ColorPicker value="yellow" disabled />
            <span>Booked dates.</span>
          </Row>
          <DatePicker
            readOnly={true}
            disabled={true}
            adjustDateOnChange={false}
            showDisabledMonthNavigation={false}
            inline
            monthsShown={calenderNumber}
            highlightDates={highlightWithRanges}
            // excludeDates={bookedDates}
            peekNextMonth={false}
            minDate={new Date()}
            focusSelectedMonth={false}
            autoFocus={false}
            disabledKeyboardNavigation={false}
          ></DatePicker>
        </div>
      ) : (
        <IsProcessing></IsProcessing>
      )}
    </div>
  );
};

export default LocumCalender;
