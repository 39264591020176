import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Spin } from "antd";
import Backdrop from "@mui/material/Backdrop";
import ScrollToTop from "./ScrollTop";
import ProtectedRoutes from "./ProtectedRoutes";
import Dashboard from "../layouts/Dashboard";
import WithNavFooter from "../layouts/WithNavFooter";
import AllPayments from "./payments/AllPayments";
import Chat from "streamChat/StreamChat";
import { NOTIFICATION_PERMISSIONS } from "utilities/helper";
import { ROLES } from "utilities/enumerations";
import { LoadingOutlined } from '@ant-design/icons';
import { GetContent } from "store/modules/contentSlice";
import { Toaster } from "react-hot-toast";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import Home from "pages/home/Home";
import Login from "pages/login/Login";
import LoginOptions from "pages/login/LoginOptions";
import ForgotPassword from "pages/forgotpassword/ForgotPassword";
import LocumBookings from "pages/userpages/Locum/LocumBookings";
import PostAvaliabilities from "pages/postavaliabilities/PostAvaliabilities";
import Resetpassword from "pages/resetpassword/Resetpassword";
import Help from "pages/help/Help";
import ErrorPage from "pages/errorpage/ErrorPage";
import InProgress from "pages/inprogress/InProgress";
import TermsConditions from "pages/termsconditions/TermsConditions";
import ContactUs from "pages/contactus/ContactUs";
import Users from "pages/userpages/Admin/Users";
import Settings from "pages/userpages/Settings";
import SignupOptions from "pages/signup/SignupOptions";
import PracticeSignup from "pages/signup/PracticeSignup";
import LocumSignup from "pages/signup/LocumSignup";
import AddAdmin from "pages/userpages/Admin/AddAdmin";
import Queries from "pages/userpages/Admin/Queries";
import UserProfile from "pages/userpages/UserProfile";
import Calculator from "./others/Calculator";
import Reviews from "pages/userpages/Reviews";
import EditUser from "pages/userpages/Admin/EditUser";
import UsersReviews from "pages/userpages/Admin/UsersReviews";
import Account from "pages/userpages/Account";
import Profile from "pages/userpages/Profile";
import EditReview from "pages/userpages/Admin/EditReview";
import ReviewBookingRequests from "pages/userpages/Locum/ReviewBookingRequests";
import AdminSettings from "pages/userpages/Admin/AdminSettings";
import SiteSettings from "pages/userpages/Admin/SiteSettings";
import DeclineBooking from "pages/userpages/Locum/DeclineBooking";
import CancelBooking from "pages/userpages/CancelBooking";
import BookingAccepted from "pages/userpages/Locum/BookingAccepted";
import PracticeBookings from "pages/userpages/Practice/PracticeBookings";
import SubsciptionCheckout from "./payments/Stripe/SubscriptionCheckout/index";
import Faq from "pages/faq/Faq";
import AboutUs from "pages/AboutUs/AboutUs";
import EmailVerification from "pages/userpages/EmailVerification";
import LocumCalender from "pages/userpages/Locum/LocumCalender";
import PracticeCalender from "pages/userpages/Practice/PracticeCalender";
import AllBookings from "pages/userpages/Admin/AllBookings";
import SiteContent from "pages/userpages/Admin/AddSiteContent";
import BrowseAvailabilities from "pages/browseavailabilities/BrowseAvailabilities";
import ManageSubscriptions from "pages/userpages/Practice/ManageSubscriptions";
import HowItWorks from "pages/HowItWorks/HowItWorks";
import useOneSignalClient from "hooks/useOneSignalClient";
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
// import "../../node_modules/bootstrap/dist/js/bootstrap.bundle";
import "react-datepicker/dist/react-datepicker.css";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import "react-confirm-alert/src/react-confirm-alert.css";
import "assets/icons/icons.css";
import "./style.scss";

library.add(fab, far, fas);

const App = () => {
  const dispatch = useDispatch();
  const backdrop = useSelector((state) => state.backdrop);
  const account = useSelector((state) => state.account);

  useOneSignalClient();

  useEffect(() => {
    document.title = "LocumsConnect";
    NOTIFICATION_PERMISSIONS()
    dispatch(GetContent())
  }, [dispatch]);

  return (
    <>
      <ScrollToTop />
      <Toaster toastOptions={{ duration: 5000 }} position="bottom-left" />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdrop.backDropOpen}
      >
        <Spin size="large" indicator={<LoadingOutlined style={{ fontSize: 60, color: "white" }} spin />} />
      </Backdrop>

      {/* Dashboard -----------------------------------------------------------------*/}
      <Routes>
        <Route element={<ProtectedRoutes />}>
          <Route exact path={"/" + ROLES[account.role]} element={<Dashboard />}>
            <Route index element={<Account />}></Route>
            <Route exact path="profile" element={<Profile />}></Route>

            {/* Admin routes later protect */}
            <Route exact path="users" element={<Users />}></Route>
            <Route exact path="usersqueries" element={<Queries />}></Route>
            <Route exact path="settings" element={<Settings />}></Route>

            <Route
              exact
              path="admin_bookings"
              element={<AllBookings />}
            ></Route>
            <Route
              exact
              path="edit_review/:id"
              element={<EditReview />}
            ></Route>
            <Route exact path="payments" element={<AllPayments />}></Route>
            <Route exact path="chat" element={<Chat />}></Route>
            <Route
              exact
              path="user_profile/:id"
              element={<UserProfile />}
            ></Route>
            <Route
              exact
              path="admin_settings"
              element={<AdminSettings />}
            ></Route>
            <Route exact path="site_settings">
              <Route index element={<SiteSettings />}></Route>
              <Route exact path="add_content" element={<SiteContent />}></Route>
            </Route>
            <Route exact path="add_admin" element={<AddAdmin />}></Route>
            {/* End admin routes */}

            <Route
              exact
              path="edit_user_profile/:id"
              element={<EditUser />}
            ></Route>
            <Route
              exact
              path="users_reviews"
              element={<UsersReviews />}
            ></Route>

            <Route
              exact
              path="manage_subscriptions"
              element={<ManageSubscriptions />}
            ></Route>

            <Route exact path="fee_calculator" element={<Calculator />}></Route>
            <Route
              exact
              path="my_calender"
              element={
                account.role === "locum" ? (
                  <LocumCalender />
                ) : (
                  <PracticeCalender />
                )
              }
            ></Route>
            <Route
              exact
              path="bookings"
              element={
                account.role === "locum" ? (
                  <LocumBookings />
                ) : (
                  <PracticeBookings />
                )
              }
            ></Route>
            <Route
              exact
              path="review_booking_requests"
              element={<ReviewBookingRequests />}
            ></Route>
            <Route
              exact
              path="decline_booking/:id"
              element={<DeclineBooking />}
            ></Route>
            <Route
              exact
              path="cancel_booking/:id"
              element={<CancelBooking />}
            ></Route>
            <Route
              exact
              path="booking_confirmed/:id"
              element={<BookingAccepted />}
            ></Route>
            <Route exact path="reviews" element={<Reviews />}></Route>
          </Route>
        </Route>

        {/* WithNavFooter -----------------------------------------------------------------*/}
        <Route exact path="/" element={<WithNavFooter />}>
          <Route index element={<Home />}></Route>
          {/* <Route element={<ProtectedRoutes />}> */}
          <Route
            exact
            path="/post_availabilities"
            element={<PostAvaliabilities />}
          ></Route>
          {/* </Route> */}
          <Route
            exact
            path="/browse_availabilities"
            element={<BrowseAvailabilities />}
          ></Route>
          <Route exact path="/help" element={<Help />}></Route>

          <Route path="/login">
            <Route index element={<LoginOptions />} />
            <Route path="locum" element={<Login />} />
            <Route path="practice" element={<Login />} />
          </Route>

          <Route path="verify_email" element={<EmailVerification />} />

          <Route path="/signup">
            <Route index element={<SignupOptions />} />
            <Route path="locum" element={<LocumSignup />} />
            <Route path="practice" element={<PracticeSignup />} />
          </Route>

          <Route exact path="/contactus" element={<ContactUs />} />
          <Route exact path="/faq" element={<Faq />} />
          <Route exact path="/about_us" element={<AboutUs />} />
          <Route exact path="/how-it-works" element={<HowItWorks />} />
          <Route
            exact
            path="/termsconditions"
            element={<TermsConditions />}
          ></Route>
          <Route
            exact
            path="/forgotpassword"
            element={<ForgotPassword />}
          ></Route>

          <Route element={<ProtectedRoutes />}>
            <Route
              exact
              path="subscription_checkout"
              element={<SubsciptionCheckout />}
            ></Route>
            <Route
              exact
              path="add_payment_method"
              element={<SubsciptionCheckout />}
            ></Route>
          </Route>
        </Route>

        {/* Without Layout -----------------------------------------------------------------*/}
        <Route path="*" element={<ErrorPage />} />
        <Route exact path="/resetpassword" element={<Resetpassword />}></Route>
        <Route exact path="/blog" element={<InProgress />}></Route>
      </Routes>
    </>
  );
};

export default App;
