/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ValidateUpdateProfilePractice } from "../../../utilities/validator";
import { UpdateProfile } from "../../../store/modules/accountSlice";
import MuiDropdown from "../../../components/others/MuiDropdown";
import {
  PRETEST,
  APPOINTMENT_TIME,
  UNPAID_BREAK,
  SOFTWARE_USED,
  EQUIPEMNT,
} from "../../../utilities/enumerations";
import ButtonCheckBox from "../../../components/others/ButtonCheckbox/ButtonCheckBox";
import Button from "@mui/material/Button";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import "./style.scss";
import MuiInput from "../../../components/others/MuiInput";
import Label from "../../../components/others/Label/Label";
import TimeRange from "../../../components/others/TimeRange/TimeRange";
import IsProcessing from "../../../components/others/IsProcessing/IsProcessing";

const PracticeProfileEdit = ({ profileName, user_id = null }) => {
  const dispatch = useDispatch();
  const { profile_data, user_token, a_is_processing } = useSelector(
    (state) => state.account
  );
  const [formError, setFormError] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [timeRange, setTimeRange] = useState({
    mon: {
      start: null,
      end: null,
    },
    tue: {
      start: null,
      end: null,
    },
    wed: {
      start: null,
      end: null,
    },
    thu: {
      start: null,
      end: null,
    },
    fri: {
      start: null,
      end: null,
    },
    sat: {
      start: null,
      end: null,
    },
    sun: {
      start: null,
      end: null,
    },
  });
  const [data, setData] = useState({
    full_name: "",
    appointment_time: "",
    unpaid_break: "",
    user_name: "",
    email: "",
    abn_number: "",
    mobile_number: "",
    sector: "",
    available_time: "",
    practice_name: "",
    practice_address: "",
    vaccination_status: "",
    other_softwares: "",
    other_equipments: "",
    other_meal_break: "",
    pretest: "",
    lat: "",
    lng: "",
    profile: "",
    softwares: [],
    equipments: [],
    time_range: {},
  });

  useEffect(() => {
    setData({ ...data, time_range: timeRange });
  }, [timeRange]);

  useEffect(() => {
    if (user_id) {
      data["user_id"] = user_id;
    }
    if (
      profile_data &&
      profile_data.basic_info &&
      profile_data.detailed_info
    ) {
      setData({
        ...data,
        ...profile_data.basic_info,
        ...profile_data.detailed_info,
      });
      setTimeRange({
        ...timeRange,
        ...profile_data.detailed_info.time_range,
      });
    }
  }, []);

  useEffect(() => {
    if (Object.keys(formError).length === 0 && isSubmitting) {
      submitForm(true);
    }
  }, [formError]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setData({ ...data, [name]: value });
    setFormError({ ...formError, [name]: "" });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormError(ValidateUpdateProfilePractice(data));
    setIsSubmitting(true);
  };

  // Software Checkbox Change
  const handleSoftwareCheckboxChange = (event) => {
    const array = data.softwares ? [...data.softwares] : [];
    if (event.target.checked) {
      const index = findIndex(array, event.target.value);
      if (index >= 0) {
        array.splice(index, 1);
      } else {
        array.push(event.target.value);
      }
    } else {
      const index = findIndex(array, event.target.value);
      if (index >= 0) {
        array.splice(index, 1);
      }
    }
    setData({ ...data, [event.target.name]: array });
  };

  // Equipment Checkbox Change
  const handleEquipmentCheckboxChange = (event) => {
    const array = data.equipments ? [...data.equipments] : [];
    if (event.target.checked) {
      const index = findIndex(array, event.target.value);
      if (index >= 0) {
        array.splice(index, 1);
      } else {
        array.push(event.target.value);
      }
    } else {
      const index = findIndex(array, event.target.value);
      if (index >= 0) {
        array.splice(index, 1);
      }
    }
    setData({ ...data, [event.target.name]: array });
  };

  const findIndex = (array, name) => {
    return array.findIndex((item) => item === name);
  };

  const validate = () => {
    let errors = {};

    const days = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];
    days.forEach(day => {
      if (!timeRange[day].start || !timeRange[day].end) {
        errors.timeRange = 'Time range is required for all days.';
      }
    });

    
    if (!data.appointment_time) errors.appointment_time = 'Appointment time is required.';
    if (!data.pretest) errors.pretest = 'Pretest is required.';
    if (!data.unpaid_break) errors.unpaid_break = 'Unpaid break is required.';
    if (data.unpaid_break === 'others' && !data.other_meal_break) {
      errors.other_meal_break = 'Other meal break is required.';
    }
    if (!data.softwares.length) errors.softwares = 'At least one software is required.';
    if (data.softwares.includes('others') && !data.other_softwares) {
      errors.other_softwares = 'Other software is required.';
    }
    if (!data.equipments.length) errors.equipments = 'At least one equipment is required.';
    if (data.equipments.includes('others') && !data.other_equipments) {
      errors.other_equipments = 'Other equipment is required.';
    }
    if (!data.profile) errors.profile = 'Profile is required.';

    setFormError(errors);
    return Object.keys(errors).length === 0;
  };

  const submitForm = (e) => {
    if (validate()) {
      // Proceed with form submission
      console.log('Form submitted successfully:', data);
      try {
        dispatch(UpdateProfile({ ...data, user_token: user_token }));
        setIsSubmitting(false);
      } catch (error) {
        console.log(error);
      }
    } else {
      console.log('validation errors:');
    }
  };

  // Time range handle change
  const handleTimeRangeChange = (timeRangeArr, dayName) => {
    setTimeRange((currVal) => {
      return {
        ...currVal,
        [dayName]: {
          ...currVal.dayName,
          start: timeRangeArr[0],
          end: timeRangeArr[1],
        },
      };
    });
  };



  return (
    <div>
      <div className="practice_acc_details">
        {!a_is_processing ? (
          <div className="row">
            <div className="col-lg-12 m-auto">
              <h3 className="text text-black">
                {profileName && profileName !== ""
                  ? profileName + " Account"
                  : "Practice Profile"}
              </h3>
              <hr></hr>
              <form onSubmit={handleSubmit}>
                {/* Time range */}
                <div className="row mt-3">
                  <div className="col">
                    <Label label={"Time available"} isRequired={true} bold={true} />
                    <TimeRange handleTimeRange={handleTimeRangeChange} timeRange={timeRange} />
                    <small className="input_errors">{formError.timeRange}</small>
                  </div>
                </div>

                {/* Appointment time */}
                <div className="row mt-3">
                  <div className="col-sm-12 col-md-6 col-lg-6">
                    <MuiDropdown
                      label={"Appointment Time"}
                      id={"appointment_time"}
                      name={"appointment_time"}
                      inputValue={data.appointment_time}
                      inputError={formError.appointment_time}
                      handleChange={handleChange}
                      options={APPOINTMENT_TIME}
                    />
                  </div>
                </div>

                {/* Pretest */}
                <div className="row mt-3">
                  <div className="col-sm-12 col-md-6 col-lg-6">
                    <MuiDropdown
                      label={"Pretest"}
                      id={"pretest"}
                      name={"pretest"}
                      inputValue={data.pretest}
                      inputError={formError.pretest}
                      handleChange={handleChange}
                      options={PRETEST}
                    />
                  </div>
                </div>

                {/* Unpaid Break */}
                <div className="row mt-3">
                  <div className="col-sm-12 col-md-6 col-lg-6">
                    <MuiDropdown
                      label={"Unpaid meal break"}
                      id={"unpaid_break"}
                      name={"unpaid_break"}
                      inputValue={data.unpaid_break}
                      inputError={formError.unpaid_break}
                      handleChange={handleChange}
                      options={UNPAID_BREAK}
                    />
                  </div>
                </div>

                {/* Other unpaid break */}
                {data.unpaid_break && data.unpaid_break === "others" && (
                  <div className="row mt-3">
                    <div className="col-sm-12 col-md-6 col-lg-6">
                      <MuiInput
                        bold={true}
                        label="Enter other unpaid meal break"
                        id="other_meal_break"
                        inputValue={data.other_meal_break}
                        type="text"
                        handleChange={handleChange}
                        name="other_meal_break"
                      />
                      {formError.other_meal_break && <small className="input_errors">{formError.other_meal_break}</small>}
                    </div>
                  </div>
                )}

                {/* Softwares Used */}
                <div className="row mt-3">
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    <label className="pass_label">Softwares Used</label>
                    <div className="mt-3">
                      {SOFTWARE_USED.map((ele, index) => (
                        <ButtonCheckBox
                          key={index}
                          name={"softwares"}
                          value={ele.name}
                          id={ele.id}
                          label={ele.label}
                          handleCheckboxChange={handleSoftwareCheckboxChange}
                          isChecked={data.softwares.includes(ele.name)}
                        />
                      ))}
                    </div>
                    {formError.softwares && <small className="input_errors">{formError.softwares}</small>}
                  </div>
                </div>

                {/* Other software used */}
                {data.softwares.includes("others") && (
                  <div className="row mt-3">
                    <div className="col-sm-12 col-md-6 col-lg-6">
                      <MuiInput
                        bold={true}
                        label="Enter other software"
                        id="other_softwares"
                        placeholder="Enter other software name"
                        inputValue={data.other_softwares}
                        type="text"
                        handleChange={handleChange}
                        name="other_softwares"
                      />
                      {formError.other_softwares && <small className="input_errors">{formError.other_softwares}</small>}
                    </div>
                  </div>
                )}

                {/* Equipment in store */}
                <div className="row mt-3">
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    <label className="pass_label">Equipment In Store</label>
                    <div className="mt-3">
                      {EQUIPEMNT.map((ele, index) => (
                        <ButtonCheckBox
                          key={index}
                          name={"equipments"}
                          value={ele.name}
                          id={ele.id}
                          label={ele.label}
                          handleCheckboxChange={handleEquipmentCheckboxChange}
                          isChecked={data.equipments.includes(ele.name)}
                        />
                      ))}
                    </div>
                    {formError.equipments && <small className="input_errors">{formError.equipments}</small>}
                  </div>
                </div>

                {/* Other equipment */}
                {data.equipments.includes("others") && (
                  <div className="row mt-3">
                    <div className="col-sm-12 col-md-6 col-lg-6">
                      <MuiInput
                        bold={true}
                        label="Enter other equipment name"
                        id="other_equipments"
                        placeholder="Enter other equipment name"
                        inputValue={data.other_equipments}
                        type="text"
                        handleChange={handleChange}
                        name="other_equipments"
                      />
                      {formError.other_equipments && <small className="input_errors">{formError.other_equipments}</small>}
                    </div>
                  </div>
                )}

                {/* Profile description */}
                <div className="row mt-3">
                  <div className="col">
                    <div className="form-group mt-2">
                      <label className="pass_label">Profile</label>
                      <textarea
                        className="form-control custom-textarea"
                        id="profile"
                        rows="3"
                        name="profile"
                        onChange={handleChange}
                        placeholder="Tell us about yourself"
                        value={data.profile}
                      />
                      <small className="input_errors">{formError.profile}</small>
                    </div>
                  </div>
                </div>

                <div className="form-group mt-4 mb-2">
                  <Button
                    type="submit"
                    sx={{
                      width: 200,
                      height: 45,
                      backgroundColor: "#4493c5",
                      ":hover": { backgroundColor: "#4493c5" },
                    }}
                    variant="contained"
                    color="primary"
                    startIcon={<SaveAltIcon />}
                  >
                    Save
                  </Button>
                </div>
              </form>
            </div>
          </div>
        ) : (
          <IsProcessing></IsProcessing>
        )}
      </div>
    </div>
  );
};

export default PracticeProfileEdit;
