import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { SetBrowseSliceSate } from "../../store/modules/browseStepsSlice";
import { Row, Col, DatePicker } from "antd"
import Label from "components/others/Label/Label";
import dayjs from "dayjs";

const DateFilters = () => {
  const dispatch = useDispatch();
  const { date, from_date, to_date } = useSelector(
    (state) => state.browseSteps
  );

  const handleDateChange = (val) => {
    dispatch(
      SetBrowseSliceSate([
        {
          bitToSet: "date",
          value: val,
        },
        {
          bitToSet: "from_date",
          value: null,
        },
        {
          bitToSet: "to_date",
          value: null,
        },
      ])
    );
  };

  const handleDateRangeChange = ([start, end]) => {
    dispatch(
      SetBrowseSliceSate([
        {
          bitToSet: "from_date",
          value: start,
        },
        {
          bitToSet: "to_date",
          value: end,
        },
      ])
    );
  };

  const handleStartDateChange = (val) => {
    dispatch(
      SetBrowseSliceSate([
        {
          bitToSet: "from_date",
          value: val,
        },
      ])
    );
  };

  const handleEndDateChange = (val) => {
    dispatch(
      SetBrowseSliceSate([
        {
          bitToSet: "to_date",
          value: val,
        },
      ])
    );
  };

  return (
    <>
      <Row>
        <Col span={24}>
          <Label label="Select date"></Label>
          <DatePicker
            className="custom_date_picker"
            value={date && date !== '' ? dayjs(date) : null}
            style={{ width: '100%', height: "50px" }}
            onChange={(val, dateStr) => { handleDateChange(dateStr); }}
          />
        </Col>
      </Row>

      <Row className="mt-4">
        <Col span={24}>
          <Label label="Select date range"></Label>
          <DatePicker.RangePicker
           popupClassName="custom-date-range-dropdown browser-date-range"
           className="browser-date-range"
            value={from_date && from_date !== '' && to_date && to_date !== '' ? [dayjs(from_date), dayjs(to_date)] : null}
            style={{ width: '100%', height: "50px" }}
            onChange={(val, dateStr) => { handleDateRangeChange(dateStr); }}
          />
        </Col>
      </Row>

      {/* <Row className="mt-4 date_range_mobile">
        <Col span={24}>
          <Label label="Select date range"></Label>
          <DatePicker
            placeholder="Select start date"
            className="custom_date_picker"
            value={from_date && from_date !== '' ? dayjs(from_date) : null}
            style={{ width: '100%', height: "50px" }}
            onChange={(val, dateStr) => { handleStartDateChange(dateStr); }}
          />
        </Col>
        <Col span={24} className="mt-3">
          <DatePicker
            placeholder="Select end date"
            className="custom_date_picker"
            value={to_date && to_date !== '' ? dayjs(to_date) : null}
            style={{ width: '100%', height: "50px" }}
            onChange={(val, dateStr) => { handleEndDateChange(dateStr); }}
          />
        </Col>
      </Row> */}
    </>

  );
};

export default DateFilters;
