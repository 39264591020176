import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ValidateUpdateAccountLocum } from "utilities/validator";
import {
  UpdateProfile,
  UploadProfilePicture,
} from "store/modules/accountSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LOCUM_SECTOR } from "utilities/enumerations";
import MuiDropdown from "components/others/MuiDropdown";
import MuiInput from "components/others/MuiInput";
import MapModal from "components/modals/MapModal";
import Profile from "assets/images/profile.png";
import Button from "@mui/material/Button";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import ImageUpload from "pages/userpages/ImageUpload";
import "./style.scss";
import AddressInput from "components/others/AddressInput/AddressInput";
import PhoneInput from "components/others/PhoneInput";
import { useLocation } from "react-router-dom";
import IsProcessing from "components/others/IsProcessing/IsProcessing";

const LocumAccount = ({ profileName, user_id = null }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { profile_data, user_token, profileImageName, a_is_processing } =
    useSelector((state) => state.account);
  const [formError, setFormError] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [data, setData] = useState({
    user_id: 0,
    user_name: "",
    email: "",
    full_name: "",
    prefix: "",
    mobile_number: "",
    ahpra_number: "",
    sector: "optometry",
    provider_number: "",
    charge_rate: "",
    charge_rate_sat: "",
    charge_rate_sun: "",
    min_shift_hours: "",
    location_subrub: "",
    location: "",
    lat: "",
    lng: "",
    distance_range: "",
    office_address: "",
    available_time: "",
    excluded_company: "",
    vaccination_status: "",
    experience_years: "",
    appointment_time: "",
    language_1: "",
    language_2: "",
    language_3: "",
    profile: "",
    other_softwares: "",
    softwares: [],
    prev_companies: [],
    excluded_companies: [],

  });

  useEffect(() => {
    if (user_id) {
      setData((prevData) => ({
        ...prevData,
        user_id: user_id,
      }));
    }
  }, [user_id]);

  //* Phone input change event
  const handlePhoneChange = (e) => {
    setData((preval) => {
      return {
        ...preval,
        mobile_number: e,
      };
    });
  };

  // * Office address change
  const handlePlaceChange = (val) => {
    // eslint-disable-next-line no-new-object
    var address_obj = new Object();
    val.address_components.forEach((element) => {
      address_obj[element.types[0]] = element.long_name;
    });
    if (val) {
      setData((prevState) => ({
        ...prevState,
        office_address: val.formatted_address,
        lng: val.geometry.location.lng(),
        lat: val.geometry.location.lat(),
        location: val.formatted_address,
        address_object: address_obj,
      }));
    }
  };

  useEffect(() => {
    if (
      profile_data &&
      profile_data.basic_info &&
      profile_data.detailed_info
    ) {
      setData((prevData) => ({
        ...prevData,
        user_name: profile_data.basic_info.user_name,
        email: profile_data.basic_info.email,
        full_name: profile_data.basic_info.full_name,
        prefix: profile_data.basic_info.prefix,
        mobile_number: profile_data.basic_info.mobile_number,


        ahpra_number: profile_data.detailed_info.ahpra_number,
        sector: profile_data.detailed_info.sector || 'optometry',
        provider_number: profile_data.detailed_info.provider_number,
        charge_rate: profile_data.detailed_info.charge_rate,
        charge_rate_sat: profile_data.detailed_info.charge_rate_sat,
        charge_rate_sun: profile_data.detailed_info.charge_rate_sun,
        min_shift_hours: profile_data.detailed_info.min_shift_hours,
        location_subrub: profile_data.detailed_info.location_subrub,
        location: profile_data.detailed_info.location,
        lat: profile_data.detailed_info.lat,
        lng: profile_data.detailed_info.lng,
        distance_range: profile_data.detailed_info.distance_range,
        office_address: profile_data.detailed_info.office_address,
        available_time: profile_data.detailed_info.available_time,
        excluded_company: profile_data.detailed_info.excluded_company,
        vaccination_status: profile_data.detailed_info.vaccination_status,
        experience_years: profile_data.detailed_info.experience_years,
        appointment_time: profile_data.detailed_info.appointment_time,
        language_1: profile_data.detailed_info.language_1,
        language_2: profile_data.detailed_info.language_2,
        language_3: profile_data.detailed_info.language_3,
        profile: profile_data.detailed_info.profile,
        other_softwares: profile_data.detailed_info.other_softwares,
        softwares: profile_data.detailed_info.softwares || [],
        prev_companies: profile_data.detailed_info.prev_companies || [],
        excluded_companies: profile_data.detailed_info.excluded_companies || [],
        time_range: profile_data.detailed_info.time_range,
      }));
    }
  }, [profile_data]);

  const submitForm = useCallback(() => {
    try {
      dispatch(UpdateProfile({ ...data, user_token: user_token }));
      setIsSubmitting(false);
    } catch (error) {
      console.log(error);
    }
  }, [data, dispatch, user_token])

  useEffect(() => {
    if (Object.keys(formError).length === 0 && isSubmitting) {
      submitForm(true);
    }
  }, [formError, isSubmitting, submitForm]);

  //* Image upload
  const imageUplaod = (imageList) => {
    const data = {
      imageBase64: imageList[0].data_url,
      imageName: imageList[0].file.name,
      imageType: imageList[0].file.type,
    }
    dispatch(
      UploadProfilePicture({
        user_token: user_token,
        imageData: data,
      })
    )
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setData({ ...data, [name]: value });
    setFormError({ ...formError, [name]: "" });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormError(ValidateUpdateAccountLocum(data));
    setIsSubmitting(true);
  };

  return (
    <div>
      <MapModal showGetDirection={false}></MapModal>
      <div className="locum_acc_details" id="acc_details">
        {location.state && location.state.is_profile_incomplete && (
          <div className="alert alert-primary" role="alert">
            <FontAwesomeIcon className="" icon="fa-solid fa-circle-info" />
            &nbsp; Your account & profile is incomplete. Please fill all the
            fields with red asterisk (
            <span
              className="text-danger"
              style={{
                lineHeight: "12px",
                verticalAlign: "bottom",
                fontSize: "25px",
              }}
            >
              *
            </span>
            ).
          </div>
        )}

        <div className="form-group user_profile_img">
          <div className="img_container">
            {profileImageName !== null ? (
              <img
                alt="profile.png"
                src={
                  process.env.REACT_APP_API_PROFILE_IMAGES_URI +
                  profileImageName
                }
              ></img>
            ) : (
              <img alt="profile.png" src={Profile}></img>
            )}
          </div>
          <ImageUpload imageUplaod={imageUplaod}></ImageUpload>
        </div>
        {!a_is_processing ? (
          <div className="row">
            <div className="col-lg-12 m-auto">
              {/* <h3 className="text text-black">
                {profileName && profileName !== ""
                  ? profileName + " Account"
                  : profile_data.basic_info.user_name +
                    " Account page"}
              </h3> */}
              <hr></hr>
              <form onSubmit={handleSubmit}>
                {/* Username, email */}
                <div className="row mt-3">
                  <div className="col-sm-12 col-md-6 col-lg-6">
                    <MuiInput
                      isRequired={true}
                      label="Username"
                      id="userName"
                      placeholder="Enter Username"
                      inputError={formError.user_name}
                      inputValue={data.user_name}
                      type="text"
                      handleChange={handleChange}
                      name="user_name"
                      disabled={true}
                    ></MuiInput>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6">
                    <MuiInput
                      isRequired={true}
                      label="Email"
                      id="userEmail"
                      placeholder="Enter your Email"
                      inputError={formError.email}
                      inputValue={data.email}
                      type="text"
                      handleChange={handleChange}
                      name="email"
                    ></MuiInput>
                  </div>
                </div>

                {/* Sector, Provider Number */}
                <div className="row mt-3">
                  <div className="col-sm-12 col-md-6 col-lg-6">
                    <MuiDropdown
                      isRequired={true}
                      label={"Sector"}
                      id={"sector"}
                      name={"sector"}
                      inputValue={data.sector}
                      inputError={formError.sector}
                      handleChange={handleChange}
                      options={LOCUM_SECTOR}
                    ></MuiDropdown>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6">
                    <AddressInput
                      label="Office Address"
                      id="address"
                      inputError={formError.location}
                      type="text"
                      inputValue={data.location}
                      handleChange={handleChange}
                      handlePlaceChange={handlePlaceChange}
                      name="location"
                    ></AddressInput>
                  </div>
                </div>

                {/* ABN No, Mobile No */}
                <div className="row mt-3">
                  <div className="col-sm-12 col-md-6 col-lg-6">
                    <MuiInput
                      isRequired={true}
                      label="Ahpra Number"
                      id="abnNumber"
                      placeholder="Enter your abn number"
                      inputError={formError.ahpra_number}
                      inputValue={data.ahpra_number}
                      type="text"
                      handleChange={handleChange}
                      name="ahpra_number"
                    ></MuiInput>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6">
                    <PhoneInput
                      isRequired={true}
                      label="Mobile Number"
                      name="mobile_number"
                      inputError={formError.mobile_number}
                      inputValue={data.mobile_number}
                      handlePhoneChange={handlePhoneChange}
                    />
                  </div>
                </div>
                {/* Submit Button */}
                <div className="form-group mt-4 mb-2">
                  <Button
                    type="submit"
                    sx={{
                      width: 200,
                      height: 45,
                      backgroundColor: "#4493c5",
                      ":hover": { backgroundColor: "#4493c5" },
                    }}
                    variant="contained"
                    color="primary"
                    startIcon={<SaveAltIcon />}
                  >
                    Save
                  </Button>
                </div>
              </form>
            </div>
          </div>
        ) : (
          <IsProcessing></IsProcessing>
        )}
      </div>
    </div>
  );
};

export default LocumAccount;
