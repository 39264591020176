import React, { useEffect, useState } from "react";
import Factory from "assets/images/factory.jpg";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import Blog from "components/cards/Blog";
import Secure from "components/cards/Secure";
import Tasker from "components/cards/Tasker";
import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import MapTest from "components/googlemaps/MapTest";
import Contactus from 'pages/contactus/ContactUs';
import { ROLES } from "utilities/enumerations";
import { useSelector } from "react-redux";
import { UserReviewLookup } from "store/modules/userSlice";
import PracticeReviewModal from "components/modals/PracticeReviewModal";
import { GET_SITE_CONTENT } from "utilities/helper";
import { Flex, Avatar, Dropdown as AntDropdown, Button } from "antd";
import "./style.scss";
import { Container, Row, Col, Card, Nav } from 'react-bootstrap';
import icon1 from 'assets/images/icon-home-1.svg'
import icon2 from 'assets/images/icon-home-2.svg'
import icon3 from 'assets/images/icon-home-3.svg'



const Home = () => {
  const dispatch = useDispatch();
  const [url, setUrl] = useState(null);
  const content = useSelector((state) => state.content);
  const account = useSelector((state) => state.account);
  const [value, setValue] = React.useState("1");
  useEffect(() => {
    document.title = "LocumsConnect | Home";
    if (
      account.is_logged_in &&
      account.user_token &&
      (account.role === ROLES.locum || account.role === ROLES.practice)
    ) {
      dispatch(UserReviewLookup({ user_token: account.user_token }));
    }
  }, [account.is_logged_in, account.role, account.user_token, dispatch]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="homePage">
      <PracticeReviewModal></PracticeReviewModal>

      <section className="bg-hero">
        <div className="container h-100">
          <div className="row h-100 d-flex align-items-center">
            <div className="col-md-12 col-lg-6">
              <div className="hero-content">
                <h1>
                  We have more <span className="fw-bold" style={{ color: "#4493c5", fontSize: "60px" }}>+100</span> <br />
                  surgens to take care of you
                </h1>
                <Nav className="mt-4">
                  <NavLink
                    className={
                      "nav-link nav-link-custom" +
                      (url === "/post_availabilities" ? " activeLinkColor" : "")
                    }
                    to="/post_availabilities"
                  >
                    Post Availability (Free)
                  </NavLink>
                  <NavLink
                    className={
                      "nav-link nav-link-custom md:mt-2" +
                      (url === "/browse_availabilities" ? " activeLinkColor" : "")
                    }
                    to="/browse_availabilities"
                    reloadDocument={true}
                  >
                    Browse Availability (Free 3 months Trail)
                  </NavLink>
                </Nav>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Call us */}
      <section className="locums-only mt-5 pt-5">
        <Container fluid='lg'>
          <div
            className="hero_section"
            dangerouslySetInnerHTML={{
              __html: GET_SITE_CONTENT(
                content.site_content_all,
                "home",
                "home_intro_para"
              ),
            }}
          ></div>
          <div className="hero_section_buttons">
            <NavLink to="/post_availabilities">
              <Button type="primary" block shape="round" style={{ height: '100%' }}>Browse Locum's Availability <div className="trial-text"> (Free 3 months Trial)</div></Button>
            </NavLink>
            <NavLink to="/browse_availabilities">
              <Button type="primary" block shape="round">Browse Locum's availability</Button>
            </NavLink>
          </div>


        </Container>
      </section>

      <section className="call-us">
        <Container fluid='lg'>
          <div
            dangerouslySetInnerHTML={{
              __html: GET_SITE_CONTENT(
                content.site_content_all,
                "home",
                "home_call_us"
              ),
            }}
          ></div>
        </Container>
      </section>

      {/* Articles Stories */}
      <section className="articlesstories">
        <Container fluid='lg'>
          <div className="row">
            <Blog Factory={Factory}></Blog>
            <Blog Factory={Factory}></Blog>
            <Blog Factory={Factory}></Blog>
          </div>
        </Container>
      </section>

      {/* Testimonials */}
      {/* <Swiper></Swiper> */}
      {/* Blog btn */}
      <section className="visit_blog">
        <Container fluid='lg'>
          <NavLink to="">
            <Button type="primary" shape="round">Visit Our Blog</Button>
          </NavLink>
        </Container>
      </section>

      {/* our-services */}
      <section className="our-services">
        <Container fluid='lg'>
          <h4 className="mb-5">Feel safe and secure on LocumsConnect</h4>
          <div className="row">
            <Secure></Secure>
            <Secure></Secure>
            <Secure></Secure>
          </div>
        </Container>
      </section>

      {/* Taskers */}
      <section className="">
        <Container fluid='lg'>
          <TabContext value={value}>
            <Box>
              <Tabs value={value} onChange={handleChange} centered>
                <Tab label="Item One" value="1" />
                <Tab label="Item Two" value="2" />
                <Tab label="Item Three" value="3" />
              </Tabs>
            </Box>
            <TabPanel value="1">
              <Tasker name={"Alex"}></Tasker>
            </TabPanel>
            <TabPanel value="2">
              <Tasker name={"John"}></Tasker>
            </TabPanel>
            <TabPanel value="3">
              <Tasker name={"Samantha"}></Tasker>
            </TabPanel>
          </TabContext>
        </Container>
      </section>

      {/* appointment */}
      <section className="appointment mt-5">
        <Container fluid='lg'>
          <h1 className="text-center font-weight-lighter">DISCOVER THE <span className="fw-bold">ONLINE</span> APPOINTMENT!</h1>
          <div className="text-center fs-4 mb-5 text-muted">Usu habeo equidem sanctus no. Suas summo id sed, erat erant oporteat cu pri. In eum omnes molestie. Sed ad debet scaevola, ne mel.</div>
          <Row>
            <Col xs={12} sm={4} md={4} lg={4} xl={4} xxl={4}>
              <Card style={{ border: 'none' }} className="py-4">
                <Card.Img variant="top" src={icon1} style={{ width: '150px', margin: '0 auto' }} />
                <Card.Body>
                  <Card.Title style={{ color: '#e74e84' }} className="text-center fs-5 pt-6">FIND A DOCTOR</Card.Title>
                  <Card.Text className="text-center">
                    Usu habeo equidem sanctus no. Suas summo id sed, erat erant oporteat cu pri. In eum omnes molestie.
                  </Card.Text>
                </Card.Body>
                <div className="arrow">
                  <span></span>
                </div>
              </Card>
            </Col>

            <Col xs={12} sm={4} md={4} lg={4} xl={4} xxl={4}>
              <Card style={{ border: 'none' }} className="py-4 second-card">
                <Card.Img variant="top" src={icon2} style={{ width: '150px', margin: '0 auto' }} />
                <Card.Body>
                  <Card.Title style={{ color: '#e74e84' }} className="text-center fs-5 pt-6">VIEW PROFILE</Card.Title>
                  <Card.Text className="text-center">
                    Usu habeo equidem sanctus no. Suas summo id sed, erat erant oporteat cu pri. In eum omnes molestie.
                  </Card.Text>
                </Card.Body>
                <div className="arrow">
                  <span></span>
                </div>
              </Card>
            </Col>

            <Col xs={12} sm={4} md={4} lg={4} xl={4} xxl={4}>
              <Card style={{ border: 'none' }} className="py-4">
                <Card.Img variant="top" src={icon3} style={{ width: '150px', margin: '0 auto' }} />
                <Card.Body>
                  <Card.Title style={{ color: '#e74e84' }} className="text-center fs-5 pt-6">BOOK A VISIT</Card.Title>
                  <Card.Text className="text-center">
                    Usu habeo equidem sanctus no. Suas summo id sed, erat erant oporteat cu pri. In eum omnes molestie.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="office_location">
        <Container fluid='lg'>
          <h5>Contact Us for a free Quote!</h5>
          <div className="row">
            <div className="col-sm-10 col-md-10 col-lg-6 home_map">
              <MapTest
                lat={33.6461432}
                lng={73.0523224}
                zoom={15}
                showGetDirection={true}
                fullscreenControl={false}
                mapTypeControl={false}
                skeletonBorderRadius={true}
              ></MapTest>
            </div>
            <div
              id="contact_us_sec"
              className="col-sm-12 col-md-12 col-lg-6 m-auto"
            >
              <Contactus isHomePage={true}></Contactus>
            </div>
          </div>
        </Container>
      </section>
    </div>
  );
};

export default Home;
