/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetUserReviews } from "../../store/modules/userSlice";
import UserReview from "../../components/cards/UserReview";
import { Col, Row } from "antd";
import IsProcessing from "../../components/others/IsProcessing/IsProcessing";
import "./style.scss";

const Reviews = () => {
  const dispatch = useDispatch();
  const account = useSelector((state) => state.account);
  const { userReviews, u_is_processing } = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(
      GetUserReviews({ user_token: account.user_token, show_loader: true })
    );
  }, []);
  return (
    <Row className="reviews" id="reviews">
      <Col span={24}>
        {!u_is_processing ? (
          <Row gutter={[10, 10]}>
            {userReviews && userReviews.length > 0 ? (
              userReviews.map((ele, index) => {
                return (
                  <Col xs={24} sm={12} md={12} lg={10} xl={6} xxl={4} key={index}>
                    <UserReview
                      index={index}
                      rating={ele.rating}
                      feedback={ele.feedback}
                      reviewerImageUrl={ele.profileImageName}
                      reviewerName={
                        ele.full_name ? ele.full_name : ele.user_name
                      }
                      reviewerId={ele.reviewer_id}
                      created_at={ele.created_at}
                      showNameBtn={true}
                    ></UserReview>
                  </Col>
                );
              })
            ) : (
              <Col span={24}>
                <p className="p-5 text-center">No reviews available.</p>
              </Col>
            )}
          </Row>
        ) : (
          <IsProcessing></IsProcessing>
        )}
      </Col>
    </Row>
  );
};

export default Reviews;
