/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Col, Row, Tabs } from "antd";
import { useDispatch, useSelector } from "react-redux";
import PracticeBookingsHistoryTable from "../../../components/tables/PracticeBookingsHistoryTable";
import MapModal from "../../../components/modals/MapModal";
import {
  GetPracticeBookingHistory,
  GetPracticeBookings,
} from "../../../store/modules/bookingSlice";
import BookingCompleteReviewModal from "../../../components/modals/BookingCompleteReviewModal";
import PracticeBookingCard from "../../../components/cards/PracticeBookingCard";
import { useLocation } from "react-router-dom";
import IsProcessing from "../../../components/others/IsProcessing/IsProcessing";
import NoResultsFound from "../../../components/others/NoResultsFound/NoResultsFound";

const PracticeBookings = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [CurrentTab, setCurrentTab] = useState(null);
  const account = useSelector((state) => state.account);
  const {
    practice_confirmed_bookings,
    practice_requested_bookings,
    practice_cancelled_bookings,
    practice_completed_bookings,
    a_is_processing,
  } = useSelector((state) => state.bookings);

  useEffect(() => {
    var tab_default = "confirmed_bookings";
    if (location.state?.tab_key) {
      tab_default = location.state?.tab_key;
    }
    dispatch(
      GetPracticeBookings({
        user_token: account.user_token,
        show_loader: true,
        type: tab_default,
      })
    );
  }, []);

  const handleTabChange = (tab) => {
    setCurrentTab(tab);
    switch (tab) {
      case "booking_history":
        dispatch(
          GetPracticeBookingHistory({
            user_token: account.user_token,
            show_loader: true,
          })
        );
        break;
      default:
        dispatch(
          GetPracticeBookings({
            user_token: account.user_token,
            show_loader: true,
            type: tab,
          })
        );
        break;
    }
  };

  return (
    <Row className="practice_bookings" id="booking_list">
      <Col span={24}>
        <h5>My Bookings</h5>
        <hr></hr>
        <Tabs
          size="large"
          defaultActiveKey={
            location.state?.tab_key
              ? location.state?.tab_key
              : "confirmed_bookings"
          }
          defaultValue={"confirmed_bookings"}
          type="card"
          onChange={(val) => {
            handleTabChange(val);
          }}
        >
          <Tabs.TabPane
            tab="Confirmed Bookings"
            tabKey="confirmed_bookings"
            key="confirmed_bookings"
          >
            <Row gutter={[10, 10]}>
              {!a_is_processing ? (
                <>
                  {practice_confirmed_bookings &&
                    practice_confirmed_bookings.length > 0 ? (
                    practice_confirmed_bookings.map((ele, index) => {
                      return (
                        <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={8} key={index}>
                          <PracticeBookingCard
                            booking_id={ele.booking_id}
                            key={index}
                            booking_status={ele.booking_status}
                            id={ele.task_id}
                            total_amount={ele.total_amount}
                            created_at={ele.created_at}
                            locum_id={ele.locum_id}
                            locum_name={ele.locum_name}
                            locum_email={ele.locum_email}
                            sector={ele.sector}
                            lat={ele.lat}
                            lng={ele.lng}
                            payment_status={ele.payment_status}
                            requestedDates={ele.requested_dates}
                            practiceTimeRange={ele.time_range}
                            show_actions={true}
                            add_calender_btn={true}
                          ></PracticeBookingCard>
                        </Col>
                      );
                    })
                  ) : (
                    <NoResultsFound text="No bookings found." />
                  )}
                </>
              ) : (
                <IsProcessing />
              )}
            </Row>
          </Tabs.TabPane>
          <Tabs.TabPane
            tab="Completed Bookings"
            tabKey="completed_bookings"
            key="completed_bookings"
          >
            <Row gutter={[10, 10]}>
              {!a_is_processing ? (
                <>
                  {practice_completed_bookings &&
                    practice_completed_bookings.length > 0 ? (
                    practice_completed_bookings.map((ele, index) => {
                      return (
                        <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={8} key={index}>
                          <PracticeBookingCard
                            booking_id={ele.booking_id}
                            key={index}
                            booking_status={ele.booking_status}
                            id={ele.task_id}
                            total_amount={ele.total_amount}
                            created_at={ele.created_at}
                            locum_id={ele.locum_id}
                            locum_name={ele.locum_name}
                            locum_email={ele.locum_email}
                            sector={ele.sector}
                            lat={ele.lat}
                            lng={ele.lng}
                            request_status={ele.request_status}
                            payment_status={ele.payment_status}
                            requestedDates={ele.requested_dates}
                            practiceTimeRange={ele.time_range}
                          ></PracticeBookingCard>
                        </Col>
                      );
                    })
                  ) : (
                    <NoResultsFound text="No bookings found." />
                  )}
                </>
              ) : (
                <IsProcessing />
              )}
            </Row>
          </Tabs.TabPane>
          <Tabs.TabPane
            tab="Requested Bookings"
            tabKey="pending_bookings"
            key="pending_bookings"
          >
            <Row gutter={[10, 10]}>
              {!a_is_processing ? (
                <>
                  {practice_requested_bookings &&
                    practice_requested_bookings.length > 0 ? (
                    practice_requested_bookings.map((ele, index) => {
                      return (
                        <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={8} key={index}>
                          <PracticeBookingCard
                            booking_id={ele.booking_id}
                            key={index}
                            booking_status={ele.booking_status}
                            id={ele.task_id}
                            total_amount={ele.total_amount}
                            created_at={ele.created_at}
                            locum_id={ele.locum_id}
                            locum_name={ele.locum_name}
                            locum_email={ele.locum_email}
                            sector={ele.sector}
                            lat={ele.lat}
                            lng={ele.lng}
                            request_status={ele.request_status}
                            payment_status={ele.payment_status}
                            requestedDates={ele.requested_dates}
                            practiceTimeRange={ele.time_range}
                            show_cancellation_btn={true}
                          ></PracticeBookingCard>
                        </Col>
                      );
                    })
                  ) : (
                    <NoResultsFound text="No bookings found." />
                  )}
                </>
              ) : (
                <IsProcessing />
              )}
            </Row>
          </Tabs.TabPane>
          <Tabs.TabPane
            tab="Declined/Cancelled bookings"
            tabKey="cancelled_bookings"
            key="cancelled_bookings"
          >
            <Row gutter={[10, 10]}>
              {!a_is_processing ? (
                <>
                  {practice_cancelled_bookings &&
                    practice_cancelled_bookings.length > 0 ? (
                    practice_cancelled_bookings.map((ele, index) => {
                      return (
                        <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={8} key={index}>
                          <PracticeBookingCard
                            booking_id={ele.booking_id}
                            key={index}
                            booking_status={ele.booking_status}
                            id={ele.task_id}
                            total_amount={ele.total_amount}
                            created_at={ele.created_at}
                            locum_id={ele.locum_id}
                            locum_name={ele.locum_name}
                            locum_email={ele.locum_email}
                            sector={ele.sector}
                            lat={ele.lat}
                            lng={ele.lng}
                            request_status={ele.request_status}
                            requestedDates={ele.requested_dates}
                            practiceTimeRange={ele.time_range}
                            cancellation_reason={ele.cancellation_reason}
                            show_cancellation_reason={true}
                            show_cancellation_btn={false}
                          ></PracticeBookingCard>
                        </Col>
                      );
                    })
                  ) : (
                    <NoResultsFound text="No bookings found." />
                  )}
                </>
              ) : (
                <IsProcessing />
              )}
            </Row>
          </Tabs.TabPane>
          <Tabs.TabPane
            tab="Booking History"
            tabKey="booking_history"
            key="booking_history"
          >
            {/* <div className="availabilities_history"> */}
            <Row>
              {!a_is_processing ? (
                <Col span={24}>
                  <PracticeBookingsHistoryTable
                    current_tab={CurrentTab}
                  ></PracticeBookingsHistoryTable>
                </Col>
              ) : (
                <IsProcessing></IsProcessing>
              )}
            </Row>

            {/* </div> */}
          </Tabs.TabPane>
        </Tabs>

        {/*--------------------- Locum location Modal ---------------------*/}
        <MapModal showGetDirection={true}></MapModal>
        {/*--------------------- Locum review Modal ---------------------*/}
        <BookingCompleteReviewModal></BookingCompleteReviewModal>
      </Col>
    </Row>
  );
};

export default PracticeBookings;
