import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { NavLink } from "react-router-dom";
import "./style.scss";
import { Col, Divider, Row } from "antd";
import AntDesignButton from "../../components/others/Button/AntDesignButton";

const LoginOptions = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Row className="login_options">
      <Col span={24}>
        <Row justify="center">
          <Col
            xs={22}
            sm={14}
            md={14}
            lg={12}
            xl={10}
            xxl={8}
            className="login_options_card"
          >
            <h5 className="mb-4 mt-2">Welcome To LocumsConnect</h5>
            <AntDesignButton
              margin_y={[10, 10]}
              block={true}
              btn_height="50"
              btn_label="Login As Locum"
              handleClick={() => {
                navigate("/login/locum", {
                  state: {
                    header: "Login to post availability",
                    from: location,
                    returnPath: location.state?.from?.pathname || "/",
                  },
                });
              }}
            />

            <AntDesignButton
              margin_y={[10, 10]}
              btn_height="50"
              block={true}
              btn_label="Login As Practice"
              handleClick={() => {
                navigate("/login/practice", {
                  state: {
                    header: "Login to request availability",
                  },
                });
              }}
            />

            <Divider></Divider>
            <Row justify="space-between">
              <small>Don't have an account ? </small>
              <NavLink className="login-links text-right" to="/signup">
                Sign up
              </NavLink>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default LoginOptions;
