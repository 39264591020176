/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { GetPracticeRequestedAndBookedDates } from "../../../store/modules/bookingSlice";
import { useDispatch, useSelector } from "react-redux";
import "./style.scss";
import { ColorPicker, Row } from "antd";
import IsProcessing from "../../../components/others/IsProcessing/IsProcessing";

const PracticeCalender = () => {
  const dispatch = useDispatch();
  const [calenderNumber] = useState(4);
  const account = useSelector((state) => state.account);
  const { practice_requested_dates, practice_booked_dates, a_is_processing } =
    useSelector((state) => state.bookings);
  const [bookedDates, setBookedDates] = useState([]);
  const [requestedDates, setRequestedDates] = useState([]);

  const highlightWithRanges = [
    {
      "react-datepicker__day--highlighted-practice_booked":
        bookedDates.length > 0 ? bookedDates : [],
    },
    {
      "react-datepicker__day--highlighted-practice_requested":
        requestedDates.length > 0 ? requestedDates : [],
    },
  ];

  // * Get practice requested and booked dates
  useEffect(() => {
    dispatch(
      GetPracticeRequestedAndBookedDates({
        user_token: account.user_token,
        show_loader: true,
      })
    );
  }, []);

  // * Populate requested dates
  useEffect(() => {
    if (practice_requested_dates && practice_requested_dates.length > 0) {
      var requestedDatesArr = practice_requested_dates.map((item) => (new Date(item)));
      setRequestedDates(requestedDatesArr);
    }
  }, [practice_requested_dates]);

  // * Populate booked dates
  useEffect(() => {
    if (practice_booked_dates && practice_booked_dates.length > 0) {
      var bookedDatesArr = practice_booked_dates.map((item) => (new Date(item)));
      setBookedDates(bookedDatesArr);
    }
  }, [practice_booked_dates]);

  return (
    <div className="my-calender-main" id="my-calender-main">
      <h5>My Calender</h5>
      <hr></hr>
      {!a_is_processing ? (
        <>
          <Row className="colors_pickers">
            <ColorPicker value="lightgrey" disabled />
            <span>Requested dates.</span>
          </Row>
          <Row className="colors_pickers">
            <ColorPicker value="yellow" disabled />
            <span>Booked dates.</span>
          </Row>
          <div className="my-calender">
            <DatePicker
              readOnly={true}
              disabled={true}
              adjustDateOnChange={false}
              showDisabledMonthNavigation={false}
              inline
              monthsShown={calenderNumber}
              highlightDates={highlightWithRanges}
              peekNextMonth={false}
              minDate={new Date()}
              focusSelectedMonth={false}
              autoFocus={false}
              disabledKeyboardNavigation={false}
            ></DatePicker>
          </div>
        </>
      ) : (
        <IsProcessing></IsProcessing>
      )}
    </div>
  );
};

export default PracticeCalender;
