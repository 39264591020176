import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { useDispatch,useSelector } from "react-redux";
import { Icon } from "@iconify/react";
import Profile from "../../assets/images/profile.png";
import { ROLES } from "../../utilities/enumerations";
import { Divider,Button } from "antd";
import "./style.scss";
import MemberStatusCard from "../cards/MemberStatusCard";
import { Logout as logoutUser } from "../../store/modules/accountSlice";
const Sidebar = () => {
  const dispatch = useDispatch();
  const account = useSelector((state) => state.account);
  const location = useLocation();
  const [url, setUrl] = useState(null);

  useEffect(() => {
    setUrl(location.pathname);
  }, [location]);

  return (
    <aside className="sidebar_main">
      <div className="sidenav_header">
        <div className="profile_img">
          {account.profileImageName != null ? (
            <img
              alt="profile.png"
              src={
                process.env.REACT_APP_API_PROFILE_IMAGES_URI +
                account.profileImageName
              }
            ></img>
          ) : (
            <img alt="profile.png" src={Profile}></img>
          )}
        </div>
        <p className="m-0 mt-3 text-center">
          {account.fullName && account.fullName !== ""
            ? account.fullName
            : account.userName}
        </p>
        <Divider></Divider>
        {account.is_logged_in && account.role === ROLES.practice && (
          <MemberStatusCard></MemberStatusCard>
        )}
      </div>
      <div className="sidenav_body">
        <ul>
          <li className="nav_item">
            <NavLink
              className={
                "nav_link" +
                (url === "/" + ROLES[account.role] ? " " : " no_active")
              }
              to={"/" + ROLES[account.role]+"#acc_details"}
            >
              <Icon
                icon="material-symbols:frame-person"
                width="20"
                height="20"
                color="#42c5f5"
              />

              <span className="nav_link_text ms-1">Account</span>
            </NavLink>
          </li>
          {(account.role === ROLES.locum ||
            account.role === ROLES.practice) && (
            <li className="nav_item">
              <NavLink
                className={
                  "nav_link" +
                  (url === `/${ROLES[account.role]}/profile`
                    ? " "
                    : " no_active")
                }
                to={"/" + ROLES[account.role] + "/profile#profile"}
              >
                <Icon
                  icon="material-symbols:account-box"
                  color="#fc8c03"
                  width="25"
                  height="25"
                />

                <span className="nav_link_text ms-1">Profile</span>
              </NavLink>
            </li>
          )}
          {account.role === ROLES.admin && (
            <li className="nav_item">
              <NavLink
                className={
                  "nav_link" +
                  (url === `/${ROLES[account.role]}/users` ? " " : "")
                }
                to={"/" + ROLES[account.role] + "/users"}
              >
                <Icon
                  icon="clarity:users-solid"
                  width="20"
                  height="20"
                  color="#f57842"
                />

                <span className="nav_link_text ms-1">Users</span>
              </NavLink>
            </li>
          )}
          {account.role === ROLES.admin && (
            <li className="nav_item">
              <NavLink
                className={
                  "nav_link" +
                  (url === `/${ROLES[account.role]}/usersqueries` ? " " : "")
                }
                to={"/" + ROLES[account.role] + "/usersqueries"}
              >
                <Icon
                  icon="mdi:head-question"
                  color="#67b856"
                  width="30"
                  height="25"
                />

                <span className="nav_link_text ms-1">Queries</span>
              </NavLink>
            </li>
          )}
          {account.role === ROLES.admin && (
            <li className="nav_item">
              <NavLink
                className={
                  "nav_link" +
                  (url === `/${ROLES[account.role]}/payments` ? " " : "")
                }
                to={"/" + ROLES[account.role] + "/payments"}
              >
                <Icon
                  icon="fluent:payment-20-filled"
                  width="20"
                  height="20"
                  color="#3f72d1"
                />

                <span className="nav_link_text ms-1">Payments</span>
              </NavLink>
            </li>
          )}

          {(account.role === ROLES.locum ||
            account.role === ROLES.practice) && (
            <li className="nav_item">
              <NavLink
                className={
                  "nav_link" +
                  (url === `/${ROLES[account.role]}/bookings` ? " " : "")
                }
                to={"/" + ROLES[account.role] + "/bookings#booking_list"}
              >
                <i className="ni ni-bullet-list-67 text-primary text-sm opacity-10"></i>

                <span className="nav_link_text ms-1">My Bookings</span>
              </NavLink>
            </li>
          )}
          {account.role === ROLES.locum && (
            <li className="nav_item">
              <NavLink
                className={
                  "nav_link" +
                  (url === `/${ROLES[account.role]}/review_booking_requests`
                    ? " "
                    : "")
                }
                to={"/" + ROLES[account.role] + "/review_booking_requests#review_booking_requests"}
              >
                <i className="ni ni-bullet-list-67 text-primary text-sm opacity-10"></i>

                <span
                  className="nav_link_text ms-1 m-0"
                  style={{ textWrap: "balance" }}
                >
                  Review Booking Requests
                </span>
              </NavLink>
            </li>
          )}
          {(account.role === ROLES.locum ||
            account.role === ROLES.practice) && (
            <li className="nav_item">
              <NavLink
                className={
                  "nav_link" +
                  (url === `/${ROLES[account.role]}/my_calender` ? " " : "")
                }
                to={"/" + ROLES[account.role] + "/my_calender#my-calender-main"}
              >
                <Icon
                  icon="uis:calender"
                  color="#42a4f5"
                  width="20"
                  height="20"
                />

                <span className="nav_link_text ms-1">My Calender</span>
              </NavLink>
            </li>
          )}
          {account.role === ROLES.practice && (
            <li className="nav_item">
              <NavLink
                className={
                  "nav_link" +
                  (url === `/${ROLES[account.role]}/reviews` ? " " : "")
                }
                to={"/" + ROLES[account.role] + "/reviews#reviews"}
              >
                <Icon icon="ic:sharp-reviews" width="20" height="20" />

                <span className="nav_link_text ms-1">Reviews</span>
              </NavLink>
            </li>
          )}
          {account.role === ROLES.locum && (
            <li className="nav_item">
              <NavLink
                className={
                  "nav_link" +
                  (url === `/${ROLES[account.role]}/reviews` ? " " : "")
                }
                to={"/" + ROLES[account.role] + "/reviews#reviews"}
              >
                <Icon icon="ic:sharp-reviews" width="20" height="20" />

                <span className="nav_link_text ms-1">Reviews</span>
              </NavLink>
            </li>
          )}
          <li className="nav_item">
            <NavLink
              className={
                "nav_link" +
                (url === `/${ROLES[account.role]}/chat` ? " active" : "")
              }
              to={"/" + ROLES[account.role] + "/chat#userChat"}
            >
              <Icon
                icon="ic:sharp-message"
                color="#13d192"
                width="25"
                height="25"
              />

              <span className="nav_link_text ms-1">Messages</span>
            </NavLink>
          </li>
          {account.role === ROLES.admin && (
            <li className="nav_item">
              <NavLink
                className={
                  "nav_link" +
                  (url === `/${ROLES[account.role]}/users_reviews` ? " " : "")
                }
                to={"/" + ROLES[account.role] + "/users_reviews"}
              >
                <Icon icon="ic:sharp-reviews" width="20" height="20" />
                <span className="nav_link_text ms-1">Reviews</span>
              </NavLink>
            </li>
          )}
          {account.role === ROLES.admin && (
            <li className="nav_item">
              <NavLink
                className={
                  "nav_link" +
                  (url === `/${ROLES[account.role]}/admin_settings` ? " " : "")
                }
                to={"/" + ROLES[account.role] + "/admin_settings"}
              >
                <Icon
                  icon="eos-icons:admin"
                  color="#5972e3"
                  width="20"
                  height="20"
                />

                <span className="nav_link_text ms-1">Admin Settings</span>
              </NavLink>
            </li>
          )}
          {account.role === ROLES.admin && (
            <li className="nav_item">
              <NavLink
                className={
                  "nav_link" +
                  (url === `/${ROLES[account.role]}/site_settings` ? " " : "")
                }
                to={"/" + ROLES[account.role] + "/site_settings"}
              >
                <Icon icon="gg:website" width="20" height="20" />

                <span className="nav_link_text ms-1">Site Content</span>
              </NavLink>
            </li>
          )}
          <li className="nav_item">
            <NavLink
              className={
                "nav_link" +
                (url === `/${ROLES[account.role]}/settings` ? " active" : "")
              }
              to={"/" + ROLES[account.role] + "/settings#settings"}
            >
              <Icon
                icon="icon-park-outline:setting-config"
                width="20"
                height="20"
                color="#29cf3d"
              />

              <span className="nav_link_text ms-1">Settings</span>
            </NavLink>
          </li>
          <li className="nav_item">
          <Button danger type="primary" block onClick={() => { dispatch(logoutUser(account.user_token)); }}>Logout</Button>
          </li>
        </ul>
      </div>
    </aside>
  );
};

export default Sidebar;
