/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  GetLocumBookingHistory,
  GetLocumBookings,
} from "../../../store/modules/bookingSlice";
import LocumBookingCard from "../../../components/cards/LocumBookingCard";
import { Col, Row, Tabs } from "antd";
import MapModal from "../../../components/modals/MapModal";
import { useLocation } from "react-router-dom";
import LocumBookingsHistoryTable from "../../../components/tables/LocumBookingsHistoryTable";
import BookingCompleteReviewModal from "../../../components/modals/BookingCompleteReviewModal";
import NoResultsFound from "../../../components/others/NoResultsFound/NoResultsFound";
import IsProcessing from "../../../components/others/IsProcessing/IsProcessing";

const Listings = () => {
  const location = useLocation();
  const account = useSelector((state) => state.account);
  const {
    locum_confirmed_bookings,
    locum_cancelled_bookings,
    locum_completed_bookings,
    a_is_processing,
  } = useSelector((state) => state.bookings);
  const [CurrentTab, setCurrentTab] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    var tab_default = "confirmed_bookings";
    if (location.state?.tab_key) {
      tab_default = location.state?.tab_key;
    }
    dispatch(
      GetLocumBookings({
        user_token: account.user_token,
        show_loader: true,
        type: tab_default,
      })
    );
  }, []);

  const handleTabChange = (tab) => {
    setCurrentTab(tab);
    switch (tab) {
      case "booking_history":
        dispatch(
          GetLocumBookingHistory({
            user_token: account.user_token,
            show_loader: true,
          })
        );
        break;
      default:
        dispatch(
          GetLocumBookings({
            user_token: account.user_token,
            show_loader: true,
            type: tab,
          })
        );
        break;
    }
  };

  return (
    <div className="locum_listings" id="booking_list">
      <h5>My Bookings</h5>
      <hr></hr>
      <Tabs
        size="large"
        defaultActiveKey={
          location.state?.tab_key
            ? location.state?.tab_key
            : "confirmed_bookings"
        }
        defaultValue={"confirmed_bookings"}
        type="card"
        onChange={(val) => {
          handleTabChange(val);
        }}
      >
        <Tabs.TabPane
          tab="Confirmed Bookings"
          tabKey="confirmed_bookings"
          key="confirmed_bookings"
        >
          <Row gutter={10}>
            {!a_is_processing ? (
              <>
                {locum_confirmed_bookings &&
                  locum_confirmed_bookings.length > 0 ? (
                  locum_confirmed_bookings.map((ele, index) => {
                    return (
                      <Col key={index} xs={24} sm={24} md={24} lg={12} xl={8} xxl={8} style={{ paddingLeft: "0px", paddingRight: "0px" }}
                      >
                        <LocumBookingCard
                          key={index}
                          id={ele.task_id}
                          booking_id={ele.booking_id}
                          total_amount={ele.total_amount}
                          created_at={ele.created_at}
                          practice_id={ele.practice_id}
                          practice_name={ele.practice_name}
                          practice_full_name={ele.practice_full_name}
                          practice_email={ele.practice_email}
                          sector={ele.sector}
                          lat={ele.lat}
                          lng={ele.lng}
                          booking_status={ele.booking_status}
                          requestedDates={ele.requested_dates}
                          practiceTimeRange={ele.time_range}
                          showRequestedDates={true}
                          showMapModalBtn={true}
                          show_actions={true}
                          add_calender_btn={true}
                        ></LocumBookingCard>
                      </Col>
                    );
                  })
                ) : (
                  <NoResultsFound text="No bookings found." />
                )}
              </>
            ) : (
              <IsProcessing />
            )}
          </Row>
        </Tabs.TabPane>
        <Tabs.TabPane
          tab="Completed Bookings"
          tabKey="completed_bookings"
          key="completed_bookings"
        >
          <Row gutter={[20, 10]}>
            {!a_is_processing ? (
              <>
                {locum_completed_bookings &&
                  locum_completed_bookings.length > 0 ? (
                  locum_completed_bookings.map((ele, index) => {
                    return (
                      <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={8} key={index}>
                        <LocumBookingCard
                          booking_id={ele.booking_id}
                          key={index}
                          booking_status={ele.booking_status}
                          id={ele.task_id}
                          total_amount={ele.total_amount}
                          created_at={ele.created_at}
                          practice_id={ele.practice_id}
                          practice_name={ele.practice_name}
                          practice_full_name={ele.practice_full_name}
                          practice_email={ele.practice_email}
                          sector={ele.sector}
                          lat={ele.lat}
                          lng={ele.lng}
                          request_status={ele.request_status}
                          requestedDates={ele.requested_dates}
                          practiceTimeRange={ele.time_range}
                        ></LocumBookingCard>
                      </Col>
                    );
                  })
                ) : (
                  <NoResultsFound text="No bookings found." />
                )}
              </>
            ) : (
              <IsProcessing />
            )}
          </Row>
        </Tabs.TabPane>
        <Tabs.TabPane
          tab="Declined/Cancelled bookings"
          tabKey="cancelled_bookings"
          key="cancelled_bookings"
        >
          <Row gutter={[10, 10]}>
            {!a_is_processing ? (
              <>
                {locum_cancelled_bookings &&
                  locum_cancelled_bookings.length > 0 ? (
                  locum_cancelled_bookings.map((ele, index) => {
                    return (
                      <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={8} key={index}>
                        <LocumBookingCard
                          booking_id={ele.booking_id}
                          key={index}
                          booking_status={ele.booking_status}
                          id={ele.task_id}
                          total_amount={ele.total_amount}
                          created_at={ele.created_at}
                          practice_id={ele.practice_id}
                          practice_name={ele.practice_name}
                          practice_full_name={ele.practice_full_name}
                          practice_email={ele.practice_email}
                          sector={ele.sector}
                          lat={ele.lat}
                          lng={ele.lng}
                          request_status={ele.request_status}
                          requestedDates={ele.requested_dates}
                          practiceTimeRange={ele.time_range}
                          cancellation_reason={ele.cancellation_reason}
                          show_cancellation_reason={true}
                        ></LocumBookingCard>
                      </Col>
                    );
                  })
                ) : (
                  <NoResultsFound text="No bookings found." />
                )}
              </>
            ) : (
              <IsProcessing />
            )}
          </Row>
        </Tabs.TabPane>
        <Tabs.TabPane
          tab="Booking History"
          tabKey="booking_history"
          key="booking_history"
        >
          {!a_is_processing ? (
            <Col span={24}>
              <LocumBookingsHistoryTable
                current_tab={CurrentTab}
              ></LocumBookingsHistoryTable>
            </Col>
          ) : (
            <IsProcessing></IsProcessing>
          )}
        </Tabs.TabPane>
      </Tabs>
      {/*--------------------- Locum location Modal ---------------------*/}
      <MapModal showGetDirection={true}></MapModal>
      {/*--------------------- Locum review Modal ---------------------*/}
      <BookingCompleteReviewModal></BookingCompleteReviewModal>
    </div>
  );
};

export default Listings;
