import React, { useRef, useState } from "react";
import {
  GoogleMap,
  useJsApiLoader,
  MarkerF,
  DirectionsRenderer,
  Autocomplete,
} from "@react-google-maps/api";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import DirectionsBikeIcon from "@mui/icons-material/DirectionsBike";
import DirectionsWalkIcon from "@mui/icons-material/DirectionsWalk";
import DirectionsBusIcon from "@mui/icons-material/DirectionsBus";
import DirectionsIcon from "@mui/icons-material/Directions";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import ArchitectureIcon from "@mui/icons-material/Architecture";
import { useDispatch } from "react-redux";
import { SetUserLocationInfo } from "../../store/modules/modalSlice";
import SaveIcon from "@mui/icons-material/Save";
import Geocode from "react-geocode";
import "./style.scss";

const MapTest = ({
  lat = 33.6461432,
  lng = 73.0523224,
  locumLocation,
  zoom = 10,
  showGetDirection = false,
  fullscreenControl = false,
  mapTypeControl = false,
  showRadiusOptions = false,
  draggable = false,
  clickable = false,
  streetViewControl = false,
  skeletonBorderRadius = false,
}) => {
  const dispatch = useDispatch();
  const [directionRes, setdirectionRes] = useState(null);
  const [distance, setDistance] = useState("");
  const [duration, setDuration] = useState("");
  const [libraries] = useState(["places"]);
  const [travelMode, setTravelMode] = useState("DRIVING");
  const [radiusError, setRadiusError] = useState("");
  const [routeError, setRouteError] = useState("");
  const [radiusCircle, setRadiusCircle] = useState([]);
  const [distanceRange, setDistanceRange] = useState(0);
  const [Center, setCenter] = useState({
    lat: parseFloat(lat),
    lng: parseFloat(lng),
  });

  const [autocomplete, setAutocomplete] = useState(null);

  const destRef = useRef();

  const onAutocompleteLoad = (autocomplete) => {
    setAutocomplete(autocomplete);
  };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    libraries: libraries,
  });
  Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAP_API_KEY);
  const [map, setMap] = useState(/** @type google.maps.Map*/ (null));

  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds(Center);
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  // * Handle goto marker
  const goToMarker = () => {
    map.panTo(Center);
  };

  // * Draw radius
  const drawRadius = () => {
    if (distanceRange == 0) {
      setRadiusError("Enter distance before drawing.");
      return;
    }
    // eslint-disable-next-line
    var antennasCircle = new google.maps.Circle({
      strokeColor: "#4493c5",
      strokeOpacity: 0.5,
      strokeWeight: 2,
      fillColor: "#4493c5",
      fillOpacity: 0.25,
      map: map,
      center: Center,
      radius: distanceRange * 1000,
    });
    setRadiusCircle([...radiusCircle, antennasCircle]);
    map.fitBounds(antennasCircle.getBounds());
  };

  // * Handle get direction
  const getDirection = async () => {
    setRouteError("");
    if (destRef.current.value === "" || locumLocation === "") {
      return;
    }
    try {
      // eslint-disable-next-line
      const directionServices = new google.maps.DirectionsService();
      const result = await directionServices.route({
        origin: Center,
        destination: destRef.current.value,
        travelMode: travelMode,
        optimizeWaypoints: true,
        provideRouteAlternatives: true,
      });
      if (result.message) {
        setRouteError("No routes found with current travel mode");
      }
      setdirectionRes(result);
      setDistance(result.routes[0].legs[0].distance.text);
      setDuration(result.routes[0].legs[0].duration.text);
    } catch (error) {
      console.log(error);
      if (error) {
        setRouteError("No routes found with current travel mode.");
        setDistance("");
        setDuration("");
      }
    }
  };

  // * Handle travel mode
  const handleChange = (e) => {
    setTravelMode(e.target.value);
    setRouteError("");
  };

  // * On Map click
  const handleMapClick = (e) => {
    setCenter({
      ...Center,
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    });
  };

  // * Clear radiius
  const clearRadius = () => {
    radiusCircle.length > 0 && radiusCircle.map((item) => item.setMap(null));
  };

  // * On marker drag
  const handleMarkerDrag = (e) => {
    setCenter({
      ...Center,
      ["lat"]: e.latLng.lat(),
      ["lng"]: e.latLng.lng(),
    });
  };

  // * Handle clear routes
  const clearRoutes = () => {
    setdirectionRes(null);
    setDistance("");
    setDuration("");
    destRef.current.value = "";
  };

  // * Handle radius change
  const handleRadiusChange = (event) => {
    setRadiusError("");
    setDistanceRange(event.target.value);
  };

  // * handle save location
  const saveLocation = () => {
    if (distanceRange == 0) {
      setRadiusError("Enter distance before saving.");
      return;
    }
    if (Center.lng && Center.lat && distanceRange) {
      Geocode.fromLatLng(Center.lat, Center.lng).then(
        (response) => {
          const address = response.results[0].formatted_address;
          dispatch(
            SetUserLocationInfo({
              userLat: Center.lat,
              userLng: Center.lng,
              distanceRange: distanceRange,
              location: address,
            })
          );
        },
        (error) => {
          console.error(error);
          setRadiusError("Server Error.");
        }
      );
    } else {
      setRadiusError("Server Error.");
    }
  };

  return (
    <>
      {!isLoaded ? (
        <Stack spacing={1} height={"100%"} width={"100%"}>
          <Skeleton
            variant="rectangular"
            width={"100%"}
            height={"100%"}
            sx={{ borderRadius: skeletonBorderRadius ? "10px" : "0px" }}
          ></Skeleton>
        </Stack>
      ) : (
        <div className="row w-100 main_map_container">
          <Tooltip title="Go To Marker" placement="left">
            <button
              className="btn btn-success map_cur_loc_btn"
              onClick={goToMarker}
            >
              <FontAwesomeIcon icon="fa-solid fa-location-crosshairs" />
            </button>
          </Tooltip>

          {showGetDirection && (
            <div className="map_destination_panel">
              <Paper
                elevation={1}
                component="form"
                sx={{
                  p: "2px 4px",
                  display: "flex",
                  alignItems: "center",
                  width: "auto",
                }}
              >
                <FormControl
                  sx={{ ":focus": { boxShadow: "none" } }}
                  size="small"
                  variant="outlined"
                >
                  <Select
                    value={travelMode}
                    id="demo-select-small"
                    displayEmpty
                    onChange={handleChange}
                  >
                    <MenuItem value={"BICYCLING"}>
                      <DirectionsBikeIcon />
                    </MenuItem>
                    <MenuItem value={"DRIVING"} defaultValue>
                      <DirectionsCarIcon />
                    </MenuItem>
                    <MenuItem value={"TRANSIT"}>
                      <DirectionsBusIcon />
                    </MenuItem>
                    <MenuItem value={"WALKING"}>
                      <DirectionsWalkIcon />
                    </MenuItem>
                  </Select>
                </FormControl>
                <Divider
                  sx={{ height: 28, m: 0.5, opacity: 1 }}
                  orientation="vertical"
                />
                <Autocomplete
                  onLoad={onAutocompleteLoad}
                  onPlaceChanged={(place) => {
                    console.log(autocomplete.getPlace());
                  }}
                  options={{
                    componentRestrictions: {
                      country: "au",
                    },
                  }}
                >
                  <InputBase
                    inputRef={destRef}
                    sx={{ ml: 1, flex: 1, maxWidth: 250 }}
                    placeholder="Search Google Maps"
                    inputProps={{ "aria-label": "search google maps" }}
                  />
                </Autocomplete>
                <Divider
                  sx={{ height: 28, m: 0.5, opacity: 1 }}
                  orientation="vertical"
                />
                <Tooltip title="Get Direction" placement="bottom">
                  <IconButton
                    onClick={getDirection}
                    color="primary"
                    sx={{ p: "10px" }}
                    aria-label="directions"
                  >
                    <DirectionsIcon />
                  </IconButton>
                </Tooltip>
                <Divider
                  sx={{ height: 28, m: 0.5, opacity: 1 }}
                  orientation="vertical"
                />
                <Tooltip title="Clear Routes" placement="bottom">
                  <IconButton
                    onClick={clearRoutes}
                    color="success"
                    sx={{ p: "10px" }}
                    aria-label="clearRoutes"
                  >
                    <ClearIcon />
                  </IconButton>
                </Tooltip>
              </Paper>

              {routeError && (
                <Paper sx={{ marginTop: 1, padding: 1, width: "fit-content" }}>
                  <small className="text-danger">{routeError}</small>
                </Paper>
              )}
              {distance && distance !== "" && duration && duration !== "" && (
                <Paper sx={{ marginTop: 1, padding: 1, width: "fit-content" }}>
                  <div className="route_info">
                    <small className="m-0 p-0 d-block">
                      Estimated distance : {distance}
                    </small>
                    <small className="m-0 p-0">
                      Estimated time : {duration}
                    </small>
                  </div>
                </Paper>
              )}
            </div>
          )}
          {showRadiusOptions && (
            <div className="map_destination_panel">
              <Paper
                elevation={1}
                component="form"
                onSubmit={(e) => {e.preventDefault();saveLocation();}}
                sx={{
                  p: "2px 4px",
                  display: "flex",
                  alignItems: "center",
                  width: "auto",
                }}
              >
                <InputBase
                  onChange={(event) => handleRadiusChange(event)}
                  sx={{ ml: 1, flex: 1, minWidth: 200 }}
                  placeholder="Enter distance in km"
                  inputProps={{
                    "aria-label": "search google maps",
                    min: 0,
                    max: 100,
                  }}
                  type="number"
                />
                <Divider
                  sx={{ height: 28, m: 0.5, opacity: 1 }}
                  orientation="vertical"
                />
                <Tooltip title="Draw Radius" placement="bottom">
                  <IconButton
                    onClick={drawRadius}
                    color="primary"
                    sx={{ p: "10px" }}
                    aria-label="directions"
                  >
                    <ArchitectureIcon />
                  </IconButton>
                </Tooltip>
                <Divider
                  sx={{ height: 28, m: 0.5, opacity: 1 }}
                  orientation="vertical"
                />
                <Tooltip title="Clear Radius" placement="bottom">
                  <IconButton
                    onClick={clearRadius}
                    color="success"
                    sx={{ p: "10px" }}
                    aria-label="clearRoutes"
                  >
                    <ClearIcon />
                  </IconButton>
                </Tooltip>
                <Divider
                  sx={{ height: 28, m: 0.5, opacity: 1 }}
                  orientation="vertical"
                />
                <Tooltip title="Save Location" placement="bottom">
                <button
                    onClick={saveLocation}
                    color="primary"
                    sx={{ p: "10px" }}
                    aria-label="directions"
                      style={{
                        backgroundColor: "#4493c5",
                        borderColor: "#4493c5",
                      }}
                      type="button"
                      className="btn btn-primary d-block"
                  >
                    SAVE
                  </button>
                </Tooltip>
              </Paper>
              {radiusError && radiusError !== "" && (
                <Paper sx={{ marginTop: 1, padding: 1 }}>
                  <small className="text-danger">{radiusError}</small>
                </Paper>
              )}
            </div>
          )}
          <GoogleMap
            onClick={(e) => (clickable ? handleMapClick(e) : void e)}
            mapContainerClassName="google_map_container"
            mapContainerStyle={{
              borderRadius: skeletonBorderRadius ? "10px" : "0px",
            }}
            center={Center}
            zoom={zoom}
            onUnmount={onUnmount}
            onLoad={onLoad}
            options={{
              mapId: "9c809b6ffaa31f64",
              fullscreenControl: fullscreenControl,
              mapTypeControl: mapTypeControl,
              streetViewControl: streetViewControl,
              mapTypeControlOptions: {
                // eslint-disable-next-line
                style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
                // eslint-disable-next-line
                position: google.maps.ControlPosition.BOTTOM_RIGHT,
              },
            }}
          >
            <MarkerF
              options={{ opacity: 0.9 }}
              // eslint-disable-next-line
              animation={google.maps.Animation.DROP}
              onDragEnd={(e) => handleMarkerDrag(e)}
              draggable={draggable}
              position={Center}
            ></MarkerF>
            {directionRes &&
              directionRes.routes.map((item, index) => {
                return (
                  <DirectionsRenderer
                    routeIndex={index}
                    options={{
                      polylineOptions: {
                        clickable: true,
                        strokeColor: index === 0 ? "#5a90d1" : "gray",
                        strokeOpacity: index === 0 ? 0.8 : 0.4,
                        strokeWeight: 6,
                        zIndex: index === 0 ? 10 : 5,
                      },
                    }}
                    directions={directionRes}
                  ></DirectionsRenderer>
                );
              })}
          </GoogleMap>
        </div>
      )}
    </>
  );
};

export default MapTest;
