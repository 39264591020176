/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Login as loginUser } from "../../store/modules/accountSlice";
import { ValidateLogin } from "../../utilities/validator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./style.scss";
import { Col, Divider, Row } from "antd";
import AntDesignButton from "../../components/others/Button/AntDesignButton";
import MuiInput from "../../components/others/MuiInput";
import MuiPasswordInput from "../../components/others/MuiPasswordInput";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.returnPath || "/";
  const account = useSelector((state) => state.account);
  const [type, settype] = useState("password");
  const [data, setData] = useState({
    user_name: "",
    password: "",
  });
  const [formError, setFormError] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [redirect, setRedirect] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setData({ ...data, [name]: value });
    setFormError({ ...formError, [name]: "" });
  };

  
  //Redirect on login
  useEffect(() => {
    document.title = "LocumsConnect | Login";
  }, []);

  useEffect(() => {
    if (account.is_logged_in && redirect) {
      setTimeout(function () {
        navigate(from, { replace: true });
      }, 1000);
    }
  }, [account.is_logged_in]);

  //Submit when form validates
  useEffect(() => {
    if (Object.keys(formError).length === 0 && isSubmitting) {
      submitForm();
    }
  }, [formError]);

  //On form submit
  const handleSubmit = (e) => {
    e.preventDefault();
    setFormError(ValidateLogin(data));
    setIsSubmitting(true);
  };

  //Toggle password visibility
  function showpasswords(val) {
    settype(val);
  }

  //Send login request
  const submitForm = async () => {
    try {
      dispatch(loginUser(data));
      setRedirect(true);
    } catch (error) {
      console.log(error);
    }
  };
  const goBack = () => {
    navigate(-1);
  };

  return (
    <Row className="login">
      <Col span={24}>
        <Row justify="center">
          <Col xs={24} sm={20} md={16} xl={9} xxl={8} className="login_card">
            <form onSubmit={handleSubmit}>
              <NavLink onClick={goBack} className="goto_login">
                <FontAwesomeIcon
                  icon="fa-solid fa-arrow-left"
                  style={{ color: "#4493c5" }}
                />
              </NavLink>
              <h5>
                {location.state &&
                  location.state.header &&
                  location.state.header}
              </h5>

              <MuiInput
                variant="outlined"
                label="Username"
                bold={false}
                id="userName"
                placeholder="Enter Username"
                inputError={formError.user_name}
                inputValue={data.user_name}
                type="text"
                handleChange={handleChange}
                name="user_name"
              ></MuiInput>

              <MuiPasswordInput
                variant="outlined"
                label="Password"
                id="userPassword"
                bold={false}
                placeholder="Enter your password"
                inputError={formError.password}
                inputValue={data.password}
                type={type}
                handleChange={handleChange}
                showpasswords={showpasswords}
                name="password"
              ></MuiPasswordInput>

              <Row justify="end">
                <p className="mt-3">
                  <NavLink to="/forgotpassword" className="login-links">
                    Forgot Password ?
                  </NavLink>
                </p>
              </Row>

              <div className="form-group mt-4">
                <AntDesignButton
                  handleClick={() => { }}
                  btn_label="Countinue"
                  htmlType="submit"
                  block={true}
                />
              </div>
              <br></br>
              <Divider></Divider>
              <Row justify="space-between">
                <p>Don't have an account?</p>
                <NavLink className="login-links text-right" to="/signup">
                  Sign up
                </NavLink>
              </Row>
            </form>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Login;
