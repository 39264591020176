import React, { useEffect } from "react";
import "./style.scss";
import { Card, Typography } from "antd";
import { GET_SITE_CONTENT } from "../../utilities/helper";
import { useSelector } from "react-redux";
import { Container, Col, Row } from 'react-bootstrap'

const AboutUs = () => {
  const content = useSelector((state) => state.content);

  useEffect(() => {
    document.title = "LocumsConnect | About Us";
  }, []);

  return (
    <section className="about_us_main">
      <Container fluid='lg'>

        <Row className="about_us_card">
          <Col sm={12} md={6}
            dangerouslySetInnerHTML={{
              __html: GET_SITE_CONTENT(
                content.site_content_all,
                "about_us",
                "about_us_header"
              ),
            }}
          ></Col>
          <Col sm={12} md={6}>
            <div
              dangerouslySetInnerHTML={{
                __html: GET_SITE_CONTENT(
                  content.site_content_all,
                  "about_us",
                  "about_us_para"
                ),
              }}
            ></div>
          </Col>
        </Row>


        <Row className="company_stats">
          <Col xs={6} sm={6} md={3}>
            <Card className="text-center" hoverable bordered={false}>
              <Typography.Title className="text-primary-b" level={1}>
                740
              </Typography.Title>
              <Typography.Text strong className="text-primary-b">
                Clean homes
              </Typography.Text>
            </Card>
          </Col>
          <Col xs={6} sm={6} md={3}>
            <Card className="text-center" hoverable bordered={false}>
              <Typography.Title className="text-primary-b" level={1}>
                740
              </Typography.Title>
              <Typography.Text strong className="text-primary-b">
                Clean homes
              </Typography.Text>
            </Card>
          </Col>
          <Col xs={6} sm={6} md={3}>
            <Card className="text-center" hoverable bordered={false}>
              <Typography.Title className="text-primary-b" level={1}>
                740
              </Typography.Title>
              <Typography.Text strong className="text-primary-b">
                Clean homes
              </Typography.Text>
            </Card>
          </Col>
          <Col xs={6} sm={6} md={3}>
            <Card className="text-center" hoverable bordered={false}>
              <Typography.Title className="text-primary-b" level={1}>
                740
              </Typography.Title>
              <Typography.Text strong className="text-primary-b">
                Clean homes
              </Typography.Text>
            </Card>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default AboutUs;
